
const FooterStyle = {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: '100%',
    //backgroundColor: 'white',
    color: 'grey',
    textAlign: 'center'
}

const Footer = () => {
    return (
        <div style={FooterStyle}>
            Criado por FF Solutions
        </div>
    )
};

export default Footer;