import { Card, CardContent, Divider, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AecFolderContext } from "../../../Provider/AecFolderProvider";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import { styled } from "@mui/material/styles";
import "../Metadata.css";

const StyledTextField = styled(TextField)({
    "& .MuiOutlinedInput-input": {
        color: "#737d85",
    },
    "& .MuiInputLabel-root": {
        color: "#737d85",
    },
});

const cardHeaderStyle = {
    display: "flex",
    gap: 10,
    alignItems: "center",
    marginBottom: 24,
};

const iconStyle = {
    fontSize: 22,
    color: "#718797",
};

const titleHeaderStyle = {
    color: "#718797",
    fontSize: 16,
};

export default function MetadataOSE({ metadataContainerRef }) {
    const { selectedFile, itemsMetadata } = useContext(AecFolderContext);
    const [fileInfo, setFileInfo] = useState();

    useEffect(() => {
        if (itemsMetadata.length && selectedFile) {
            if (selectedFile) {
                var customAtt = itemsMetadata.filter(
                    (item) => item.folder_item_id === selectedFile.id
                );
                setFileInfo(customAtt);
            } else {
                setFileInfo();
            }
        } else {
            setFileInfo();
        }
    }, [selectedFile, itemsMetadata]);

    function getValue(attName) {
        try {
            if (fileInfo) {
                return fileInfo[0][attName] === "" ||
                    fileInfo[0][attName] === "-" ||
                    fileInfo[0][attName] === undefined ||
                    fileInfo[0][attName] === null
                    ? " "
                    : fileInfo[0][attName];
            } else {
                return " ";
            }
        } catch (ex) {
            return " ";
        }
    }

    return (
        <div ref={metadataContainerRef} className="metadata-info-container">
            <Card className="card-metadata">
                <div style={cardHeaderStyle}>
                    <PlagiarismOutlinedIcon style={iconStyle} />
                    <label style={titleHeaderStyle}>
                        Detalhes do documento
                    </label>
                </div>
                <Divider />
                <CardContent className="card-content-metadata">
                    <div className="card-metadata-subgrid">
                        {/*fields*/}
                        <StyledTextField
                            label="Nome"
                            value={getValue("item_name")}
                            className="textfield"
                            size="small"
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    <StyledTextField
                        label="Imobilizado"
                        value={getValue("imobilizado")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Data de Incorporação"
                        value={getValue("data_incorporacao")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Data de Depreciacao"
                        value={getValue("data_depreciacao")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Classe do imobilizado"
                        value={getValue("classe_imobilizado")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Descrição da Classe "
                        value={getValue("descricao_classe")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Vida Útil (meses) BRR"
                        value={getValue("vida-vida_util_meses")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="UAR"
                        value={getValue("uar")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Denominação do Imobilizado"
                        value={getValue("denom_imobilizado")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Quantidade"
                        value={getValue("quantidade")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Unidade de Medida"
                        value={getValue("unidade_medida")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Centro de Custo"
                        value={getValue("centro_custo")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Sigla Centro de Custo"
                        value={getValue("sigla_centro_custo")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Processo Operacional"
                        value={getValue("processo_operacional")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Descrição do Processo Operacional"
                        value={getValue("desc_processo_operacional")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Planta Global"
                        value={getValue("planta_global")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Descrição Planta Global"
                        value={getValue("desc_planta_global")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Imobilizado de Origem"
                        value={getValue("imob_origem")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Denominação Complementar"
                        value={getValue("denom_complementar")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="PEP"
                        value={getValue("pep")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="UP"
                        value={getValue("up")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Descrição UP"
                        value={getValue("desc_up")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Vida Útil (meses) BRR"
                        value={getValue("vida_util_meses_brr")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Descricao UAR"
                        value={getValue("desc_uar")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Data da 1ª Aquisição (dia, mês e ano)*"
                        value={getValue("dt_primeira_aquisicao")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Valor da Aquisição (Societária)"
                        value={getValue("valor_aquis_societaria")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Valor Contábil Residual (Societária)"
                        value={getValue("valor_cont_resi_societaria")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Valor de Aquisição BRR (IPCA)"
                        value={getValue("valor_aquis_brr_ipca")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Valor Contábil Residual BRR (IPCA)"
                        value={getValue("valor_cont_resi_brr_ipca")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Exercício da Aquisição (ano e mês)*"
                        value={getValue("exercicio_aquisicao")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Material (Tubulação)"
                        value={getValue("material_tubulacao")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Diâmetro (Tubulação)"
                        value={getValue("diametro_tubulacao")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Método Construtivo (Tubulação)"
                        value={getValue("metodo_const_tubulacao")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Localização"
                        value={getValue("localizacao")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Descrição da Localização"
                        value={getValue("desc_localizacao")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Administrador do Contrato"
                        value={getValue("adm_contrato")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Dt/ Aqui/ Cont/ Orig (BRR)"
                        value={getValue("dt_aqui_cont_orig_brr")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Situação"
                        value={getValue("situacao")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Descrição da Situação"
                        value={getValue("desc_situacao")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Desativado Em"
                        value={getValue("desativado_em")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Modelo"
                        value={getValue("modelo")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Marca"
                        value={getValue("marca")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="UN"
                        value={getValue("un")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Município"
                        value={getValue("municipio")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Tipo de Documento"
                        value={getValue("tipo_documento")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Contrato SAP"
                        value={getValue("contrato_sap")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Contrato SGL"
                        value={getValue("contrato_sgl")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Número Protocolo SAT"
                        value={getValue("n_protocolo_sat")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Data Protocolo SAT"
                        value={getValue("dt_protocolo_sat")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Informações do Recebimento SAT"
                        value={getValue("info_recebimento_sat")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </CardContent>
            </Card>
        </div>
    );
}
