import { createContext, useState } from "react";


const defaultMessageTimeout = 2010;

export const AlertContext = createContext({});
export const AlertProvider = ({ children }) => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    function displaySuccessAlert(message) {
        setSuccessMessage(message);
        setShowSuccessAlert(true);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, defaultMessageTimeout)
    }

    function displayErrorAlert(message) {
        setErrorMessage(message)
        setShowErrorAlert(true);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, defaultMessageTimeout)
    }

    return (
        <AlertContext.Provider
            value={{
                displaySuccessAlert,
                displayErrorAlert,
                errorMessage,
                successMessage,
                showSuccessAlert,
                showErrorAlert
            }}>
            {children}
        </AlertContext.Provider>
    );
}