import React from 'react';
import styles from './index.module.css';

import Typography from '@mui/material/Typography';

/**
 * Generic title component.
 * @param {string} content - The text content of the title.
 * @param {string} [id] - The id attribute of the title.
 */
const Title = ({ content, id }) => {
  return (
    <Typography variant="h4" className={styles.title} id={id}>
      {content}
    </Typography>
  )
}

export default Title;