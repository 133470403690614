import { Card, CardContent, Divider, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import { styled } from "@mui/material/styles";
import "./Metadata.css";
import { format, parseISO } from "date-fns";

const StyledTextField = styled(TextField)({
    "& .MuiOutlinedInput-input": {
        color: "#737d85",
    },
    "& .MuiInputLabel-root": {
        color: "#737d85",
    },
});

const cardHeaderStyle = {
    display: "flex",
    gap: 10,
    alignItems: "center",
    marginBottom: 24,
};

const iconStyle = {
    fontSize: 22,
    color: "#718797",
};

const titleHeaderStyle = {
    color: "#718797",
    fontSize: 16,
};

export const MetadataEsgoto = ({ metadataContainerRef }) => {
    const { selectedFile, itemsMetadata } = useContext(AecFolderContext);
    const [fileInfo, setFileInfo] = useState();

    useEffect(() => {
        if (itemsMetadata.length && selectedFile) {
            if (selectedFile) {
                var customAtt = itemsMetadata.filter(
                    (item) => item.folder_item_id === selectedFile.id
                );
                setFileInfo(customAtt);
            } else {
                setFileInfo();
            }
        } else {
            setFileInfo();
        }
    }, [selectedFile, itemsMetadata]);

    function getValue(attName) {
        try {
            if (fileInfo) {
                return fileInfo[0][attName] === "" ||
                    fileInfo[0][attName] === "-" ||
                    fileInfo[0][attName] === undefined ||
                    fileInfo[0][attName] === null
                    ? " "
                    : fileInfo[0][attName];
            } else {
                return " ";
            }
        } catch (ex) {
            return " ";
        }
    }

    return (
        <div ref={metadataContainerRef} className="metadata-info-container">
            <Card className="card-metadata">
                <div style={cardHeaderStyle}>
                    <PlagiarismOutlinedIcon style={iconStyle} />
                    <label style={titleHeaderStyle}>
                        Detalhes do documento
                    </label>
                </div>
                <Divider />
                <CardContent className="card-content-metadata">
                    <div className="card-metadata-subgrid">
                        <StyledTextField
                            label="Título"
                            value={getValue("titulo")}
                            className="textfield"
                            size="small"
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    <StyledTextField
                        label="Lote"
                        value={getValue("lote")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Nº do Contrato"
                        value={getValue("n_contrato")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Nº de Cadastro"
                        value={getValue("n_cadastro")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Nº da Contratada"
                        value={getValue("n_contratada")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Nº"
                        value={getValue("numero")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Estação Elevatória de Esgotos (EEE)"
                        value={getValue("eee")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Estação de Tratamento de Esgotos (ETE)"
                        value={getValue("ete")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Emissário"
                        value={getValue("emissario")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Coletor Tronco"
                        value={getValue("coletor_tronco")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Interceptor"
                        value={getValue("interceptor")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Especialidade"
                        value={getValue("especialidade")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Bacia de Esgotamento"
                        value={getValue("bacia_esgotamento")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Município"
                        value={getValue("municipio")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Coordenada norte"
                        className="textfield"
                        value={getValue("coord_norte")}
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Coordenada leste"
                        value={getValue("coord_leste")}
                        size="small"
                        disabled
                        className="textfield"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Diâmetro/Medidas"
                        value={getValue("diametro_medidas")}
                        size="small"
                        disabled
                        className="textfield"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Material"
                        value={getValue("material")}
                        size="small"
                        disabled
                        className="textfield"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Subárea do projeto"
                        value={getValue("subarea_projeto")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Método construtivo"
                        value={getValue("metodo_construtivo")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Data de Instalação"
                        value={new Date(
                            getValue("data_instalacao")
                        ).toLocaleDateString()}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Inserido por"
                        value={getValue("inserido_por")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Localização Física"
                        value={getValue("localizacao_fisica")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </CardContent>
            </Card>
        </div>
    );
};
