import { useTheme } from "@mui/material";
import sabespLogoLight from "../../assets/sabesp_logo-light.png";
import sabespLogoDark from "../../assets/sabesp_logo-dark.png";

const sabespLogoStyle = {
    width: "70px",
    height: "70px",
};
export const SabespLogo = () => {
    const theme = useTheme();

    return (
        <>
            <img
                style={sabespLogoStyle}
                key={theme.palette.mode}
                src={
                    theme.palette.mode === "dark"
                        ? sabespLogoLight
                        : sabespLogoDark
                }
                alt="Logo Sabesp"
            />
        </>
    );
};
