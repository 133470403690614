import { getUserData } from '../../../service/AecFolderService';
import { api } from './configs/axiosConfig'
import { defineCancelApiObject } from './configs/axiosUtils'

/**
 * API functions for file-related operations.
 * @namespace filesApi
 */
export const filesApi = {

  /**
   * Initiates file migration to Migration SAT backend with user data and e-mail information for the SQS Message function.
   * @async
   * @param {File} file - The file to be migrated.
   * @param {boolean} [cancel=false] - Flag indicating whether to enable request cancellation.
   */
  sendMessage: async function (file, selectedProject, cancel = false) {
    const user = await getUserData();
    const formData = new FormData();
    const projectId = selectedProject.startsWith("b.") ? selectedProject.slice(2) : selectedProject;
    formData.append('file', file);
    formData.append('project_id', projectId);
    formData.append('user', user.data.name);
    formData.append('user_email', user.data.email);

    const response = await api.request({
      headers: { "Content-Type": "multipart/form-data" },
      url: `${process.env.REACT_APP_MIGRATION_BACKEND_URL}api/autodesk/send-sqs-message`,
      method: "POST",
      data: formData,
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })

    return { status: response.status, message: response.data.message }
  }

}




const cancelApiObject = defineCancelApiObject(filesApi) // Object for managing request cancellation for each API endpoint in filesApi
