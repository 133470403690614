import { Card } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../containers/footer";
import { useContext, useEffect } from "react";
import { ProjectContext } from "../../Provider/ProjectProvider";
import FormEsgoto from "../../components/Form/Esgoto";
import FormSidebar from "../../components/Form/Sidebar";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import ItemSearch from "../../components/ItemSearch";

const cardContainer = {
  borderRadius: "8px",
  padding: "24px",
  margin: "24px 54px",
  display: "grid",
};

const Esgoto = () => {
  const { folderContents } = useContext(AecFolderContext);
  const { changeSelectedProject, Projects } = useContext(ProjectContext);

  useEffect(() => {
    changeSelectedProject(Projects.ESGOTO);
  });

  return (
    <>
      <Header />
      <main>
        <Card style={cardContainer}>
          <FormSidebar />
          <FormEsgoto />
          {folderContents.length ? <ItemSearch /> : <></>}
        </Card>
      </main>
      <Footer />
    </>
  );
};

export default Esgoto;
