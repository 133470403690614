import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Alert, Snackbar, Link } from "@mui/material";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import { AecFolderContext } from "../../../Provider/AecFolderProvider";
import FormAutocomplete from "../Autocomplete";
import FormButton from "../Button";
import { AlertContext } from "../../../Provider/AlertProvider";
import { CustomProvider, DateRangePicker } from "rsuite";
import { useNavigate } from "react-router-dom";

import "./ArquivoTecnico.css";

const FormPEC = () => {
    const {
        form,
        changeFormPec,
        itemsMetadataPec,
        handleClearPec,
        loadFieldPec,
        filterFolderContentsPec,
        handleHelp,
    } = useContext(AecFolderContext);

    const { errorMessage, successMessage, showSuccessAlert, showErrorAlert } =
        useContext(AlertContext);

    const [formKey, setFormKey] = useState({
        itemNameKey: Math.random(),
        unKey: Math.random(),
        municipioKey: Math.random(),
        tipoDocumentoKey: Math.random(),
        contratoSapKey: Math.random(),
        contratoSglKey: Math.random(),
        numeroProtocoloSatKey: Math.random(),
        dataProtocoloSatKey: Math.random(),
        nomeFornecedorKey: Math.random(),
        tipoContratoKey: Math.random(),
        dataContrato: Math.random(),
    });

    const [defaultDataProtocoloSat, setDefaultDataProtocoloSat] = useState();
    const [defaultDataContrato, setDefaultDataContrato] = useState();
    const [dataProtocoloSat, setDataProtocoloSat] = useState();
    const [dataContrato, setDataContrato] = useState();
    let navigate = useNavigate();    

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    function enviarData(data, field) {
        if (field === "dataProtocoloSat") {
            setDataProtocoloSat(data);
            const startDate = data[0].toLocaleDateString();
            const endDate = data[1].toLocaleDateString();

            const dateRangeString = `${startDate} - ${endDate}`;
            changeForm("dataProtocoloSat", "onChange", data, dateRangeString);
        }
        if (field === "dataContrato") {
            setDataContrato(data);
            const startDate = data[0].toLocaleDateString();
            const endDate = data[1].toLocaleDateString();

            const dateRangeString = `${startDate} - ${endDate}`;
            changeForm("dataContrato", "onChange", data, dateRangeString);
        }
    }

    const handleClear = async () => {
        setFormKey({
            itemNameKey: Math.random(),
            unKey: Math.random(),
            municipioKey: Math.random(),
            tipoDocumentoKey: Math.random(),
            contratoSapKey: Math.random(),
            contratoSglKey: Math.random(),
            numeroProtocoloSatKey: Math.random(),
            dataProtocoloSatKey: Math.random(),
            nomeFornecedorKey: Math.random(),
            tipoContratoKey: Math.random(),
            dataContrato: Math.random(),
        });
        await handleClearPec();
        setDataProtocoloSat(null);
        setDataContrato(null);
        setDefaultDataProtocoloSat([new Date(itemsMetadataPec.dataProtocoloSatde), new Date(itemsMetadataPec.dataProtocoloSatate),]);
        setDefaultDataContrato([new Date(itemsMetadataPec.dataContratode), new Date(itemsMetadataPec.dataContratoate),]);
        forceUpdate();
    };

    const changeForm = (field, method, e = {}, newValue = "", reason = "") => {
        changeFormPec(field, method, e, newValue, reason);
        forceUpdate();
    };

    const onScrollField = async (currentTarget, field) => {
        if (
            Math.round(currentTarget.scrollTop) +
            Math.round(currentTarget.clientHeight) ===
            Math.round(currentTarget.scrollHeight)
        ) {
            await loadFieldPec(
                "pec",
                "onScroll",
                field,
                Math.round(itemsMetadataPec[field].length / 200)
            );
            forceUpdate();
        }
    };

    const handleMigration = () => {
        let path = `/migracao`;
        navigate(path);
    };

    return (
        <>
            <div className="fields-container-geral">
                <FormAutocomplete
                    id="itemName"
                    key={formKey.itemNameKey}
                    options={itemsMetadataPec.itemName}
                    label="Nome do Arquivo"
                    onChange={(e, nv, reason) =>
                        changeForm("itemName", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => { if (e.key === "Enter") changeForm("itemName", "onKeyUp", e, e.target.value.toUpperCase(), reason) }}
                    onChangeTextfield={(e, nv,) => {
                        changeForm("itemName", "onChangeTextfield", e, e.target.value)
                    }}
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "itemName"),
                        role: "list-box",
                    }}
                />

                <div className="sub-grid-mun">
                    <FormAutocomplete
                        id="municipio"
                        key={formKey.municipioKey}
                        options={itemsMetadataPec.municipio}
                        label="Município"
                        onChange={(e, nv, reason) =>
                            changeForm("municipio", "onChange", e, nv.toUpperCase(), reason)
                        }
                        onKeyUp={(e, nv, reason) => { if (e.key === "Enter") changeForm("municipio", "onKeyUp", e, e.target.value.toUpperCase(), reason) }}
                        onChangeTextfield={(e) =>
                            changeForm("municipio", "onChangeTextfield", e, e.target.value)
                        }
                        filterOptions={(options) => options}
                        listboxProps={{
                            onScroll: async ({ e, currentTarget }) =>
                                await onScrollField(currentTarget, "municipio"),
                            role: "list-box",
                        }}
                    />
                    <FormAutocomplete
                        id="un"
                        key={formKey.unKey}
                        options={itemsMetadataPec.un}
                        label="UN"
                        onChange={(e, nv, reason) =>
                            changeForm("un", "onChange", e, nv.toUpperCase(), reason)
                        }
                        onKeyUp={(e, nv, reason) => { if (e.key === "Enter") changeForm("un", "onKeyUp", e, e.target.value.toUpperCase(), reason) }}
                        onChangeTextfield={(e) =>
                            changeForm("un", "onChangeTextfield", e, e.target.value)
                        }
                        filterOptions={(options) => options}
                        listboxProps={{
                            onScroll: async ({ e, currentTarget }) =>
                                await onScrollField(currentTarget, "un"),
                            role: "list-box",
                        }}
                    />
                </div>
                <div className="sub-grid-tipo-doc">
                    <FormAutocomplete
                        id="tipoDocumento"
                        key={formKey.tipoDocumentoKey}
                        options={itemsMetadataPec.tipoDocumento}
                        label="Tipo do Documento"
                        onChange={(e, nv, reason) =>
                            changeForm("tipoDocumento", "onChange", e, nv.toUpperCase(), reason)
                        }
                        onKeyUp={(e, nv, reason) => { if (e.key === "Enter") changeForm("tipoDocumento", "onKeyUp", e, e.target.value.toUpperCase(), reason) }}
                        onChangeTextfield={(e) =>
                            changeForm("tipoDocumento", "onChangeTextfield", e, e.target.value)
                        }
                        filterOptions={(options) => options}
                        listboxProps={{
                            onScroll: async ({ e, currentTarget }) =>
                                await onScrollField(currentTarget, "tipoDocumento"),
                            role: "list-box",
                        }}
                    />
                </div>
                <div className="sub-grid-contrato">
                    <FormAutocomplete
                        id="contratoSap"
                        key={formKey.contratoSapKey}
                        options={itemsMetadataPec.contratoSap}
                        label="Contratado SAP"
                        onChange={(e, nv, reason) =>
                            changeForm("contratoSap", "onChange", e, nv.toUpperCase(), reason)
                        }
                        onKeyUp={(e, nv, reason) => { if (e.key === "Enter") changeForm("contratoSap", "onKeyUp", e, e.target.value.toUpperCase(), reason) }}
                        onChangeTextfield={(e) =>
                            changeForm("contratoSap", "onChangeTextfield", e, e.target.value)
                        }
                        filterOptions={(options) => options}
                        listboxProps={{
                            onScroll: async ({ e, currentTarget }) =>
                                await onScrollField(currentTarget, "contratoSap"),
                            role: "list-box",
                        }}
                    />
                    <FormAutocomplete
                        id="numeroProtocoloSat"
                        key={formKey.numeroProtocoloSatKey}
                        options={itemsMetadataPec.numeroProtocoloSat}
                        label="N° Protocolo SAT"
                        onChange={(e, nv, reason) =>
                            changeForm("numeroProtocoloSat", "onChange", e, nv.toUpperCase(), reason)
                        }
                        onKeyUp={(e, nv, reason) => { if (e.key === "Enter") changeForm("numeroProtocoloSat", "onKeyUp", e, e.target.value.toUpperCase(), reason) }}
                        onChangeTextfield={(e) =>
                            changeForm("numeroProtocoloSat", "onChangeTextfield", e, e.target.value)
                        }
                        filterOptions={(options) => options}
                        listboxProps={{
                            onScroll: async ({ e, currentTarget }) =>
                                await onScrollField(currentTarget, "numeroProtocoloSat"),
                            role: "list-box",
                        }}
                    />
                </div>
                <div className="sub-grid-contratoslg">
                    <FormAutocomplete
                        id="contratoSgl"
                        key={formKey.contratoSglKey}
                        options={itemsMetadataPec.contratoSgl}
                        label="Contratado SGL"
                        onChange={(e, nv, reason) =>
                            changeForm("contratoSgl", "onChange", e, nv.toUpperCase(), reason)
                        }
                        onKeyUp={(e, nv, reason) => {
                            if (e.key === "Enter")
                                changeForm("contratoSgl", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                        }}
                        onChangeTextfield={(e) =>
                            changeForm("contratoSgl", "onChangeTextfield", e, e.target.value)
                        }
                        filterOptions={(options) => options}
                        listboxProps={{
                            onScroll: async ({ e, currentTarget }) =>
                                await onScrollField(currentTarget, "contratoSgl"),
                            role: "list-box",
                        }}
                    />
                    <FormAutocomplete
                        id="tipoContrato"
                        key={formKey.tipoContratoKey}
                        options={itemsMetadataPec.tipoContrato}
                        label="Tipo de Contrato"
                        onChange={(e, nv, reason) =>
                            changeForm("tipoContrato", "onChange", e, nv.toUpperCase(), reason)
                        }
                        onKeyUp={(e, nv, reason) => {
                            if (e.key === "Enter")
                                changeForm("tipoContrato", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                        }}
                        onChangeTextfield={(e) =>
                            changeForm("tipoContrato", "onChangeTextfield", e, e.target.value)
                        }
                        filterOptions={(options) => options}
                        listboxProps={{
                            onScroll: async ({ e, currentTarget }) =>
                                await onScrollField(currentTarget, "tipoContrato"),
                            role: "list-box",
                        }}
                    />
                </div>

                <DateRangePicker
                    format="dd/MM/yyyy"
                    placeholder="Data Contrato"
                    showOneCalendar={true}
                    style={{ width: 300 }}
                    defaultCalendarValue={
                        defaultDataContrato || [
                            new Date(itemsMetadataPec.dataContratode),
                            new Date(itemsMetadataPec.dataContratoate),
                        ]
                    }
                    value={dataContrato}
                    onOk={(e) => enviarData(e, "dataContrato")}
                />
                <FormAutocomplete
                    id="nomeFornecedor"
                    key={formKey.nomeFornecedorKey}
                    options={itemsMetadataPec.nomeFornecedor}
                    label="Nome do Fornecedor"
                    onChange={(e, nv, reason) =>
                        changeForm("nomeFornecedor", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("nomeFornecedor", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("nomeFornecedor", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "nomeFornecedor"),
                        role: "list-box",
                    }}
                />
                <DateRangePicker
                    format="dd/MM/yyyy"
                    placeholder="Data Protocolo Sat"
                    showOneCalendar={true}
                    defaultCalendarValue={
                        defaultDataProtocoloSat || [
                            new Date(itemsMetadataPec.dataProtocoloSatde),
                            new Date(itemsMetadataPec.dataProtocoloSatate),
                        ]
                    }
                    value={dataProtocoloSat}
                    onOk={(e) => enviarData(e, "dataProtocoloSat")}
                />
            </div>

            <div className="buttons-container">
                
                <FormButton
                    id="Migracao"
                    text="Migração"
                    onClick={() => {
                        handleMigration();
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<BackupOutlinedIcon />}
                />
                <Link
                    href="https://www.youtube.com/watch?v=7FQpNy9_3q4"
                    target="_blank"
                    underline="none"
                >
                    <FormButton
                        id="Ajuda"
                        text="Ajuda"
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<InfoOutlinedIcon />}
                    />
                </Link>
                <FormButton
                    id="Limpar"
                    text="Limpar"
                    onClick={() => {
                        handleClear();
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<RefreshSharpIcon />}
                />
                <FormButton
                    id="Pesquisar"
                    text="Pesquisar"
                    onClick={() => {
                        filterFolderContentsPec(form);
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SearchSharpIcon />}
                />
            </div>

            <Snackbar
                open={showSuccessAlert}
                sx={{ height: "100%" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="success">{successMessage}</Alert>
            </Snackbar>
            <Snackbar
                open={showErrorAlert}
                sx={{ height: "100%" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
        </>
    );
};

export default FormPEC;
