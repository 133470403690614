import { createContext, useContext, useEffect, useState } from "react";
import { Buffer } from "buffer";
import {
  getFileInfo,
  getFilter,
  getProjectsPermissions,
  getSearch,
  saveLog,
  getUserData,
  getResultLogs,
  get_filterAdm,
  getAccessPermissions,
} from "../../service/AecFolderService";
import { getTwoLeggedToken } from "../../service/AuthService";
import { saveFile, compressFiles } from "../../service/BucketService";
import { LoadingContext } from "../LoadingProvider";
import { AlertContext } from "../AlertProvider";
import { ProjectContext } from "../ProjectProvider";

/**
 * Context to manage AEC folder-related state and actions.
 *
 * @constant
 * @type {object}
 */
export const AecFolderContext = createContext({});

/**
 ** Provider component for AEC folder context.
 ** Manages state and actions related to AEC folders.
 *
 * @component
 * @param {object} children - React components nested inside the provider.
 */
export const AecFolderProvider = ({ children }) => {
  //GENERAL
  const { setLoading, loadingTimeout } = useContext(LoadingContext);
  const [itemsMetadata, setItemsMetadata] = useState([]);
  const [projectsPermissions, setProjectsPermissions] = useState([]);
  const [link, setLink] = useState([]);
  const [form, setForm] = useState({});
  const [isAdministrationScreen, setIsAdministrationScreen] = useState(false);

  //AGUA
  const { displaySuccessAlert, displayErrorAlert } = useContext(AlertContext);
  const { selectedProject, Projects } = useContext(ProjectContext);
  const [folderContents, setFolderContents] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFileDownloadUrl, setSelectedFileDownloadUrl] = useState();

  /**
   * Metadata specific to Esgot o project, Used for conditional filter based on selected field.
   **/
  const [itemsMetadataAgua] = useState({
    codSabesp: [],
    titulo: [],
    especialidade: [],
    subAreaProjeto: [],
    areaProjeto: [],
    sistema: [],
  });

  /**
   ** Variable to track the previous page.
   ** Used for items that require pagination every 200 items
   ** initialized with -1 when displaying the first page it receives +1 which results in page 0
   */
  /**
   * Metadata specific to Esgot o project, Used for conditional filter based on selected field.
   **/
  const [itemsMetadataEsgoto] = useState({
    lote: [],
    nContrato: [],
    nCadastro: [],
    nContratada: [],
    numero: [],
    eee: [],
    ete: [],
    emissario: [],
    coletorTronco: [],
    interceptor: [],
    especialidade: [],
    titulo: [],
    baciaEsgotamento: [],
    municipio: [],
  }); //

  /**
   * Metadata specific to PEC at Arquivo project, Used for conditional filter based on selected field.
   **/
  const [itemsMetadataPec] = useState({
    itemName: [],
    un: [],
    municipio: [],
    tipoDocumento: [],
    contratoSap: [],
    contratoSgl: [],
    numeroProtocoloSat: [],
    dataProtocoloSatde: [],
    dataProtocoloSatate: [],
    nomeFornecedor: [],
    tipoContrato: [],
    dataContratode: [],
    dataContratoate: [],
  });

  /**
   * Metadata specific to OSE at Arquivo project, Used for conditional filter based on selected field.
   **/
  const [itemsMetadataOse] = useState({
    itemName: [],
    imobilizado: [],
    classeImobilizado: [],
    descricaoClasse: [],
    uar: [],
    centroCusto: [],
    plantaGlobal: [],
    imobOrigem: [],
    pep: [],
    exercicioAquisicao: [],
    materialTubulacao: [],
    diametroTubulacao: [],
    metodoConstrutivoTubulacao: [],
    localizacao: [],
    desativadoEm: [],
    un: [],
    municipio: [],
    contratoSap: [],
    contratoSgl: [],
    numeroProtocoloSat: [],
    informacoesRecebimentoSat: [],
    dataIncorporacaode: [],
    dataIncorporacaoate: [],
    dataDepreciacaode: [],
    dataDepreciacaoate: [],
    dataProtocoloSatde: [],
    dataProtocoloSatate: [],
  });

  //Constant to receive the tab clicked by the user in the File project. Initialized with 0 because tab 0 is the PEC tab
  const [selectedTab, setSelectedTab] = useState(0);
  const [projectName, setProjectName] = useState("");

  const [userData] = useState({
    userName: "",
    fullName: "",
    company: "",
  });

  const [itemsTableLogs] = useState({
    user_full_name: [],
    company: [],
    action: [],
    project: [],
    file_name: [],
    file_id: [],
    date: [],
    hour: [],
  });
  //#region general methods

  //method that designates the action to be taken from the project
  useEffect(() => {
    async function verifySelectedProject() {
      switch (selectedProject) {
        case Projects.AGUA:
          clearFolderContents(); //
          setProjectName("AGUA");
          await loadDataAgua("onLoad");
          setItemsMetadata(itemsMetadataAgua);
          break;
        case Projects.ESGOTO:
          clearFolderContents();
          setProjectName("ESGOTO");
          await loadDataEsgoto("onLoad");
          setItemsMetadata(itemsMetadataEsgoto);
          break;
        case Projects.ARQUIVO:
          verifySelectTab();
          break;
        default:
      }
    }

    verifySelectedProject();
  }, [selectedProject]);

  /**
   * Executes the `verifySelectTab` method when the selectedProject is ARQUIVO and the selectedTab changes.
   *
   * @returns {void}
   */
  useEffect(() => {
    // Check if the selectedProject is ARQUIVO and trigger the method
    if (selectedProject === Projects.ARQUIVO) {
      verifySelectTab();
    }
  }, [selectedTab]);

  /**
   * Checks the selected tab and performs corresponding actions.
   * Clears folder contents, loads data based on the tab, and updates itemsMetadata accordingly.
   *
   * @returns {Promise<void>} A promise that resolves when the actions are completed.
   */
  async function verifySelectTab() {
    if (selectedTab === 0) {
      clearFolderContents();
      setProjectName("SAT-PEC");
      await loadDataPec("onLoad");
      setItemsMetadata(itemsMetadataPec);
    } else {
      clearFolderContents();
      setProjectName("SAT-OSE");
      await loadDataOse("onLoad");
      setItemsMetadata(itemsMetadataOse);
    }
  }

  /**
   * Handles the change of the selected tab value.
   *
   * @param {object} event - The event object.
   * @param {number} newValue - The new value of the selected tab.
   * @returns {void}
   */
  function handleChangeTab(event, newValue) {
    setSelectedTab(newValue);
  }

  /**
   * Handles the help action, displaying an error alert indicating that it's under development.
   *
   * @returns {void}
   */
  function handleHelp() {
    displayErrorAlert("Em desenvolvimento");
  }

  /**
   * Function to fetch user data and access permissions.
   * Updates the user data with fetched information.
   * @returns {void}
   */
  async function getUser() {
    setLoading(true);

    var requestDatas = await getUserData();

    userData["userName"] = requestDatas.data.given_name;
    userData["fullName"] = requestDatas.data.name;
    userData["image"] = requestDatas.data.picture;
    userData["email"] = requestDatas.data.email;

    var requestDatasAccess = await getAccessPermissions(userData.email);

    userData["access"] = requestDatasAccess[0].access_level;
    userData["company"] = requestDatasAccess[0].company_name;

    setLoading(false);
  }

  /**
   * Logs a user action with the provided information.
   * @param {string} action - The action performed by the user.
   * @param {string} [fileName=""] - The name of the file involved in the action (optional).
   * @param {string} [fileId=""] - The ID of the file involved in the action (optional).
   * @param {string} [projectName=""] - The name of the project involved in the action (optional).
   */
  async function LogTrigger(
    action,
    fileName = "",
    fileId = "",
    projectName = ""
  ) {
    if (userData["userName"] === "") await getUser();
    userData["file"] = fileName;
    userData["fileId"] = fileId;
    userData["userAction"] = action;
    userData["project"] = projectName;

    saveLog(userData);
  }

  /**
   ** Fetches and sets user permissions from the server.
   ** Enables screens that the user has equal access to in ACC
   * @returns {void}
   */
  async function getUsersPermissions() {
    try {
      let result = await getProjectsPermissions();
      let items = result.data;
      let permissions = items.map((x) => x.id);
      setProjectsPermissions(permissions);
    } catch (ex) {
      getUsersPermissions();
    }
  }

  /**
   ** Moves to the next document in the folderContents list.
   ** Prepares the file viewer to view the next Document
   * @returns {void}
   */
  function onNextDocument() {
    let indexSelectedFile = folderContents.indexOf(selectedFile);
    if (folderContents.length > indexSelectedFile + 1) {
      let nextFile = folderContents[indexSelectedFile + 1];
      setSelectedFile(nextFile);
      prepareViewer(nextFile);
    }
  }

  /**
   ** Moves to the previous document in the Folder Contents list.
   ** Prepares the file viewer to view the previous Document
   * @returns {void}
   */
  function onPreviousDocument() {
    let indexSelectedFile = folderContents.indexOf(selectedFile);
    if (indexSelectedFile - 1 >= 0) {
      let nextFile = folderContents[indexSelectedFile - 1];
      setSelectedFile(nextFile);
      prepareViewer(nextFile);
    }
  }
  /**
   ** Prepares the viewer for the file by searching for the selected file in the results list.
   ** Set the viewer link and determine the viewer type based on the file extension..
   *
   * @param {object} selectedFile - The selected file object.
   * @returns {void}
   */
  async function prepareViewer(selectedFile) {
    var token;

    try {
      setLoading(true);

      var fileName = selectedFile.attributes.displayName;
      var fileId = selectedFile.attributes.id;

      LogTrigger("VISUALIZAÇÃO", fileName, fileId, projectName);

      var twoLeggedTokenResponse = await getTwoLeggedToken();
      token = twoLeggedTokenResponse.data.access_token;

      var fileInfoResult = await getFileInfo(selectedFile.id, selectedProject);

      prepareDownload(fileName, token, fileInfoResult);

      const name = fileName.toUpperCase();

      setLink(fileInfoResult.data.data.links.webView.href);

      if (
        name.endsWith(".PDF") ||
        name.endsWith(".JPG") ||
        name.endsWith(".TIF") ||
        name.endsWith(".DWG")
      ) {
        prepareViewerPdf(token, fileInfoResult);
      }

      setLoading(false);
    } catch (ex) {
      setLink("");
      setLoading(false);
    }
  }

  /**
   * Prepares the PDF viewer, converting the file URN and making the viewer call.
   * In case of an error, falls back to the default viewer call.
   *
   * @param {string} token - The authentication token.
   * @param {object} fileInfoResult - The result object containing file information.
   * @returns {void}
   */
  function prepareViewerPdf(token, fileInfoResult) {
    try {
      var base64EncodedUrn = Buffer.from(fileInfoResult.data.included[0].id)
        .toString("base64")
        .replace("/", "_");

      window.callViewer(base64EncodedUrn.replace("=", ""), token);
    } catch (ex) {
      window.callDefaultViewer(token);
    }
  }

  /**
   * Prepares the download of a file by obtaining the download link and saving the file.
   * @param {string} fileName - The name of the file to be downloaded.
   * @param {string} token - The authentication token for authorization headers.
   * @param {Object} fileInfoResult - The result object containing file information.
   * @returns {void}
   */
  async function prepareDownload(fileName, token, fileInfoResult) {
    try {
      var downloadFileUrl =
        fileInfoResult.data.included[0].relationships.storage.meta.link.href;
      var downloadFileResult = await saveFile(downloadFileUrl, fileName, token);
      setSelectedFileDownloadUrl(downloadFileResult.data.url);
    } catch (ex) {
      setSelectedFileDownloadUrl(downloadFileResult.data.url);
    }
  }

  /**
   ** Scrolls through all files in the results list
   ** Starts the file compression process, downloads the compressed file and resets the upload state.
   ** In case of error, displays an error alert.
   *
   * @param {Array} selectedFiles - An array of files selected for download (optional).
   * @returns {void}
   */
  async function downloadAllFiles(selectedFiles) {
    try {
      setLoading(true);
      var filesList = [];
      var twoLeggedTokenResponse = await getTwoLeggedToken();
      var token = twoLeggedTokenResponse.data.access_token;
      let files = selectedFiles ? selectedFolders : folderContents;
      for (const file of files) {
        let fileInfoResult = await getFileInfo(file.id, selectedProject);
        let downloadFileUrl =
          fileInfoResult.data.included[0].relationships.storage.meta.link.href;
        filesList.push({
          name: file.attributes.displayName,
          url: downloadFileUrl,
        });
        LogTrigger(
          "DOWLOAD MULTIPLOS ARQUIVOS",
          file.attributes.displayName,
          file.id,
          projectName
        );
      }
      await compressFiles(filesList, token);

      window.location.href =
        process.env.REACT_APP_SERVER_API_BASE_URL + "compress/download";
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
      displayErrorAlert(
        "Houve um problema na hora do download, contate o administrador do sistema"
      );
    }
  }

  /**
   * method to prevent field options from being duplicated
   ** checks if all arguments are different from the previous one
   ** returns true, informing that the field has a scroll with more than 200 items and it must be updated.
   ** If not, it returns false, preventing a field that does not have more than 200 items from having its options duplicated.
   * @param {number} page - The current page number.
   * @param {Array} item - The array of items to check.
   * @param {string} method - The method triggering the permission check (e.g., "onScroll", "onFilter", "onClear", "onLoad").
   * @returns {boolean} - Returns true if permission needs to be validated; otherwise, returns false.
   */
  function checkPermission(page, item, method, fieldCaptalize) {
    return (
      window[fieldCaptalize] !== page &&
      (!item.length > 0 ||
        (method === "onScroll" && page > 0) ||
        method === "onFilter" ||
        method === "onClear" ||
        method === "onLoad")
    );
  }

  /**
   * Converts a frontend field name to the corresponding database column name.
   ** The conversion involves transforming camelCase into snake_case.
   *
   * @param {string} field - The frontend field name to be converted.
   * @returns {string} - The corresponding database column name.
   */
  function formatFieldToColumn(field) {
    return field
      .replace(/[A-Z][a-z]*/g, (str) => "_" + str.toLowerCase() + "_")
      .replace("__", "_")
      .replace(/(^_)|(_$)/g, "");
  }

  /**
   * Clears the metadata, file preview area, and Forge Viewer.
   ** This function is responsible for resetting various state variables
   ** And removing any existing content from the Forge Viewer.
   *
   * @returns {void}
   */
  function clearFolderContents() {
    //Set loading, displays the loading image on the screen

    //Clear the current form,
    //Do as not to impact the display of options on the other screen
    //As the objects within the form passed through the where clause in the api.
    setForm({});
    setFolderContents([]);
    setSelectedFile({});
    setSelectedFileDownloadUrl();
    var htmlDiv = document.getElementById("forgeViewer");
    if (htmlDiv) htmlDiv.innerHTML = "";
  }

  //#endregion

  //#region agua methods
  /**
   * Loads data for the 'agua' project. Invokes 'loadFieldAgua' method for each field.
   *
   * @param {string} method - The method triggering the data load.
   * @returns {void}
   */
  async function loadDataAgua(method = "") {
    setLoading(true);
    for (const property in itemsMetadataAgua) {
      let fieldCaptalize =
        "previousPage" + property[0].toUpperCase() + property.slice(1);
      window[fieldCaptalize] = -1;

      await loadFieldAgua("agua", method, property);
    }
    setLoading(false);
  }

  /**
   * Handles the 'Clear' action for the 'agua' project. Clears form, folder contents, and triggers data reload.
   *
   * @returns {void}
   */
  async function handleClearAgua() {
    await loadDataAgua("onClear");
    setForm({});
    clearFolderContents();
    loadingTimeout(7000);
  }

  /**
   * Loads data for a specific field in the 'agua' project.
   *
   * @param {string} project - The project name ('agua').
   * @param {string} method - The method triggering the data load.
   * @param {string} field - The field for which data needs to be loaded.
   * @param {number} page - The page number for paginated loading (default is 0).
   * @returns {void}
   */
  async function loadFieldAgua(project, method, field, page = 0) {
    try {
      let fieldCaptalize =
        "previousPage" + field[0].toUpperCase() + field.slice(1);
      setLoading(true);
      if (
        checkPermission(page, itemsMetadataAgua[field], method, fieldCaptalize)
      ) {
        let column = formatFieldToColumn(field);
        let result = await getFilter(project, column, page);

        window[fieldCaptalize] = page;

        if (result.data !== itemSelectedAgua[field])
          itemsMetadataAgua[field] =
            method === "onClear" || method === "onLoad"
              ? result.data
              : itemsMetadataAgua[field].concat(result.data);
      }
      setLoading(false);
    } catch (ex) {
      loadFieldAgua(project, method, field, page);
    }
  }

  /**
   * Handles the selection or input of an item in the form auto-complete or the input field.
   * Invokes a conditional filter for the 'agua' project based on the selected item or input value.
   *
   * @param {Object} form - The form object containing field-value pairs.
   * @param {string} field - The field being selected or typed into.
   * @param {string} value - The selected item or typed input value.
   * @param {number} page - The page number for paginated loading (default is 0).
   * @returns {void}
   */
  async function itemSelectedAgua(form, field, value, page = 0) {
    setLoading(true);
    let fieldCaptalize =
      "previousPage" + field[0].toUpperCase() + field.slice(1);
    window[fieldCaptalize] = -1;

    for (const property in itemsMetadataAgua) {
      let column = formatFieldToColumn(property);
      let result = await getFilter("agua", column, page, form);
      addItemsAgua(property, result.data, page);
    }
    setLoading(false);
  }
  /**
   * Adds items to the metadataAgua for a specific field in the 'agua' project based on a conditional check.
   *
   * @param {string} field - The field for which items are being added.
   * @param {Array} items - The items to be added to the metadata.
   * @param {number} page - The page number for paginated loading.
   * @param {string} method - The method triggering the addition (default is 'onFilter').
   * @returns {void}
   */
  function addItemsAgua(field, items, page, method = "onFilter") {
    if (checkPermission(page, itemsMetadataAgua[field], method))
      itemsMetadataAgua[field] = items;
  }
  /**
   * Filters folder contents in the 'agua' project based on the provided form filters.
   ** Displays success or error alerts based on the search results.
   *
   * @param {Object} form - The form object containing field-value pairs for filtering.
   * @returns {void}
   */
  async function filterFolderContentsAgua(form) {
    setLoading(true);

    if (Object.keys(form).length === 0) {
      setFolderContents([]);
      setLoading(false);
      displayErrorAlert("É obrigatório o preenchimento de ao menos um filtro");
      return;
    }

    var filteredList = await getSearch("agua", form);

    loadFilteredFilesAgua(filteredList.data).then(() => {
      setLoading(false);
      if (filteredList.data.length > 0)
        displaySuccessAlert("Pesquisa realizada com sucesso");
      else displayErrorAlert("Não existem registros com esses filtros");
    });
  }

  /**
   * Sends the filtered files from the 'agua' project based on the list provided in filterFolderContentsAgua.
   ** The filtered files will go to itemsMetadata, where they will be displayed in results on the screen, after the user clicks 'Pesquisar'.
   *
   * @param {Array} filteredList - The list of filtered items.
   * @returns {void}
   */
  async function loadFilteredFilesAgua(filteredList) {
    setFolderContents(
      filteredList
        .map((item) => {
          return {
            id: item.folder_item_id,
            attributes: {
              id: item.folder_item_id,
              displayName: item.display_name,
            },
          };
        })
        .sort((a, b) => {
          return (
            parseInt(a.attributes.displayName.split("-")[0]) -
            parseInt(b.attributes.displayName.split("-")[0])
          );
        })
    );

    setItemsMetadata(filteredList);
  }
  /**
   ** Handles changes in the form fields for the 'agua' project based on the specified method send by FormAutoComplete.
   ** Updates the form object and triggers relevant actions accordingly.
   *
   * @param {string} field - The field being modified in the form.
   * @param {string} method - The method indicating the type of change (e.g., "onChange", "onKeyUp").
   * @param {Object} e - The event object (default empty object).
   * @param {string} nv - The new value (default empty string).
   * @param {string} reason - The reason for the change (default empty string).
   * @returns {void}
   */
  function changeFormAgua(field, method, e = {}, nv = "", reason = "") {
    switch (method) {
      case "onChange":
        form[field] = nv.toUpperCase();
        if (reason === "selectOption") form[field + "Selected"] = true;
        itemSelectedAgua(form, field, nv.toUpperCase());
        break;
      case "onKeyUp":
        if (e.key === "Enter") filterFolderContentsAgua(form);
        else
          setForm({
            ...form,
            [field]: nv.toUpperCase(),
          });
        break;
      case "onChangeTextfield":
        if (nv === "") {
          delete form[field];
          delete form[field + "Searched"];
          delete form[field + "Selected"];
        } else {
          form[field] = nv.toUpperCase();
          form[field + "Searched"] = true;
        }

        if (nv.length === 0 || nv.length > 3)
          itemSelectedAgua(form, field, nv.toUpperCase());
        break;
      default:
        break;
    }
  }

  //#region esgoto methods
  /**
   * Loads data for the 'esgoto' project. Invokes 'loadFielEsgoto' method for each field.
   *
   * @param {string} method - The method triggering the data load.
   * @returns {void}
   */
  async function loadDataEsgoto(method = "") {
    setLoading(true);
    for (const property in itemsMetadataEsgoto) {
      let fieldCaptalize =
        "previousPage" + property[0].toUpperCase() + property.slice(1);
      window[fieldCaptalize] = -1;
      await loadFieldEsgoto("esgoto", method, property);
    }
    setLoading(false);
  }

  /**
   * Handles the 'Clear' action for the 'esgoto' project. Clears form, folder contents, and triggers data reload.
   *
   * @returns {void}
   */
  async function handleClearEsgoto() {
    await loadDataEsgoto("onClear");
    setForm({});
    clearFolderContents();
    loadingTimeout(7000);
  }

  /**
   * Loads data for a specific field in the 'esgoto' project.
   *
   * @param {string} project - The project name ('esgoto').
   * @param {string} method - The method triggering the data load.
   * @param {string} field - The field for which data needs to be loaded.
   * @param {number} page - The page number for paginated loading (default is 0).
   * @returns {void}
   */
  async function loadFieldEsgoto(project, method, field, page = 0) {
    try {
      let fieldCaptalize =
        "previousPage" + field[0].toUpperCase() + field.slice(1);
      setLoading(true);

      if (
        checkPermission(
          page,
          itemsMetadataEsgoto[field],
          method,
          fieldCaptalize
        )
      ) {
        let column = formatFieldToColumn(field);
        let result = await getFilter(project, column, page);

        window[fieldCaptalize] = page;

        if (result.data !== itemSelectedEsgoto[field])
          itemsMetadataEsgoto[field] =
            method === "onClear" || method === "onLoad"
              ? result.data
              : itemsMetadataEsgoto[field].concat(result.data);
      }
      setLoading(false);
    } catch (ex) {
      loadFieldEsgoto(project, method, field, page);
    }
  }

  /**
   * Handles the selection or input of an item in the form auto-complete or the input field.
   * Invokes a conditional filter for the 'esgoto' project based on the selected item or input value.
   *
   * @param {Object} form - The form object containing field-value pairs.
   * @param {string} field - The field being selected or typed into.
   * @param {string} value - The selected item or typed input value.
   * @param {number} page - The page number for paginated loading (default is 0).
   * @returns {void}
   */
  async function itemSelectedEsgoto(form, field, value, page = 0) {
    setLoading(true);
    let fieldCaptalize =
      "previousPage" + field[0].toUpperCase() + field.slice(1);
    window[fieldCaptalize] = -1;

    for (const property in itemsMetadataEsgoto) {
      let column = formatFieldToColumn(property);
      let result = await getFilter("esgoto", column, page, form);
      addItemsEsgoto(property, result.data, page);
    }
    setLoading(false);
  }

  /**
   * Adds items to the metadataEsgoto for a specific field in the 'esgoto' project based on a conditional check.
   *
   * @param {string} field - The field for which items are being added.
   * @param {Array} items - The items to be added to the metadata.
   * @param {number} page - The page number for paginated loading.
   * @param {string} method - The method triggering the addition (default is 'onFilter').
   * @returns {void}
   */
  function addItemsEsgoto(field, items, page, method = "onFilter") {
    if (checkPermission(page, itemsMetadataEsgoto[field], method))
      itemsMetadataEsgoto[field] = items;
  }

  /**
   * Filters folder contents in the 'esgoto' project based on the provided form filters.
   ** Displays success or error alerts based on the search results.
   *
   * @param {Object} form - The form object containing field-value pairs for filtering.
   * @returns {void}
   */
  async function filterFolderContentsEsgoto(form) {
    setLoading(true);

    if (Object.keys(form).length === 0) {
      setFolderContents([]);
      setLoading(false);
      displayErrorAlert("É obrigatório o preenchimento de ao menos um filtro");
      return;
    }

    var filteredList = await getSearch("esgoto", form);

    loadFilteredFilesEsgoto(filteredList.data).then(() => {
      setLoading(false);
      if (filteredList.data.length > 0)
        displaySuccessAlert("Pesquisa realizada com sucesso");
      else displayErrorAlert("Não existem registros com esses filtros");
    });
  }

  /**
   * Sends the filtered files from the 'esgoto' project based on the list provided in filterFolderContentsEsgoto'
   ** The filtered files will go to itemsMetadata, where they will be displayed in results on the screen, after the user clicks 'Pesquisar'.
   *
   * @param {Array} filteredList - The list of filtered items.
   * @returns {void}
   */
  async function loadFilteredFilesEsgoto(filteredList) {
    setFolderContents(
      filteredList
        .map((item) => {
          return {
            id: item.folder_item_id,
            attributes: {
              id: item.folder_item_id,
              displayName: item.item_name,
            },
          };
        })
        .sort((a, b) => {
          return (
            parseInt(a.attributes.displayName.split("-")[0]) -
            parseInt(b.attributes.displayName.split("-")[0])
          );
        })
    );
    setItemsMetadata(filteredList);
  }

  /**
   ** Handles changes in the form fields for the 'esgoto' project based on the specified method send by FormAutoComplete.
   ** Updates the form object and triggers relevant actions accordingly.
   *
   * @param {string} field - The field being modified in the form.
   * @param {string} method - The method indicating the type of change (e.g., "onChange", "onKeyUp").
   * @param {Object} e - The event object (default empty object).
   * @param {string} nv - The new value (default empty string).
   * @param {string} reason - The reason for the change (default empty string).
   * @returns {void}
   */
  function changeFormEsgoto(field, method, e = {}, nv = "", reason = "") {
    switch (method) {
      case "onChange":
        form[field] = nv.toUpperCase();
        if (reason === "selectOption") form[field + "Selected"] = true;
        itemSelectedEsgoto(form, field, nv.toUpperCase());
        break;
      case "onKeyUp":
        if (e.key === "Enter") filterFolderContentsEsgoto(form);
        else form[field] = nv.toUpperCase();
        break;
      case "onChangeTextfield":
        if (nv === "") {
          delete form[field];
          delete form[field + "Searched"];
          delete form[field + "Selected"];
        } else {
          form[field] = nv.toUpperCase();
          form[field + "Searched"] = true;
        }

        if (nv.length === 0 || nv.length > 3)
          itemSelectedEsgoto(form, field, nv.toUpperCase());
        break;
      default:
        break;
    }
  }

  //#region pec methods
  /**
   * Loads data for the 'Pec' in the Arquivo Tecnico project. Invokes 'loadFieldPec' method for each field.
   *
   * @param {string} method - The method triggering the data load.
   * @returns {void}
   */
  async function loadDataPec(method = "") {
    setLoading(true);
    for (const property in itemsMetadataPec) {
      let fieldCaptalize =
        "previousPage" + property[0].toUpperCase() + property.slice(1);
      window[fieldCaptalize] = -1;

      await loadFieldPec("pec", method, property);
    }
    setLoading(false);
  }

  /**
   * Handles the 'Clear' action for the 'pec' in the Arquivo tecnico project. Clears form, folder contents, and triggers data reload.
   *
   * @returns {void}
   */
  async function handleClearPec() {
    await loadDataPec("onClear");
    clearFolderContents();
    loadingTimeout(7000);
  }

  /**
   * Loads data for a specific field in the 'pec' in the Arquivo project.
   *
   * @param {string} project - The project name ('pec').
   * @param {string} method - The method triggering the data load.
   * @param {string} field - The field for which data needs to be loaded.
   * @param {number} page - The page number for paginated loading (default is 0).
   * @returns {void}
   */
  async function loadFieldPec(project = "pec", method, field, page = 0) {
    try {
      let fieldCaptalize =
        "previousPage" + field[0].toUpperCase() + field.slice(1);
      if (checkPermission(page, itemsMetadataPec[field], method)) {
        let column = formatFieldToColumn(field);
        let result = await getFilter(project, column, page);

        window[fieldCaptalize] = page;

        if (result.data !== itemSelectedPec[field])
          itemsMetadataPec[field] =
            method === "onClear" || method === "onLoad"
              ? result.data
              : itemsMetadataPec[field].concat(result.data);
      }
    } catch (ex) {
      loadFieldPec(project, method, field, page);
    }
  }
  /**
   * Handles the selection or input of an item in the form auto-complete or the input field.
   * Invokes a conditional filter for the 'pec' project based on the selected item or input value.
   *
   * @param {Object} form - The form object containing field-value pairs.
   * @param {string} field - The field being selected or typed into.
   * @param {string} value - The selected item or typed input value.
   * @param {number} page - The page number for paginated loading (default is 0).
   * @returns {void}
   */
  async function itemSelectedPec(form, field, value, page = 0) {
    setLoading(true);
    let fieldCaptalize =
      "previousPage" + field[0].toUpperCase() + field.slice(1);
    window[fieldCaptalize] = -1;

    for (const property in itemsMetadataPec) {
      let column = formatFieldToColumn(property);
      let result = await getFilter("pec", column, page, form);
      addItemsPec(property, result.data, page);
    }
    setLoading(false);
  }
  /**
   * Adds items to the metadataAgua for a specific field in the 'pec' project based on a conditional check.
   *
   * @param {string} field - The field for which items are being added.
   * @param {Array} items - The items to be added to the metadata.
   * @param {number} page - The page number for paginated loading.
   * @param {string} method - The method triggering the addition (default is 'onFilter').
   * @returns {void}
   */
  function addItemsPec(field, items, page, method = "onFilter") {
    if (checkPermission(page, itemsMetadataPec[field], method))
      itemsMetadataPec[field] = items;
  }

  /**
   * Filters folder contents in the 'pec' project based on the provided form filters.
   ** Displays success or error alerts based on the search results.
   *
   * @param {Object} form - The form object containing field-value pairs for filtering.
   * @returns {void}
   */
  async function filterFolderContentsPec(form) {
    setLoading(true);

    if (Object.keys(form).length === 0) {
      setFolderContents([]);
      setLoading(false);
      displayErrorAlert("É obrigatório o preenchimento de ao menos um filtro");
      return;
    }

    var filteredList = await getSearch("pec", form);

    loadFilteredFilesPec(filteredList.data).then(() => {
      setLoading(false);
      if (filteredList.data.length > 0)
        displaySuccessAlert("Pesquisa realizada com sucesso");
      else displayErrorAlert("Não existem registros com esses filtros");
    });
  }
  /**
   * Sends the filtered files from the 'pec' project based on the list provided in filterFolderContentsPec.
   ** The filtered files will go to itemsMetadata, where they will be displayed in results on the screen, after the user clicks 'Pesquisar'.
   *
   * @param {Array} filteredList - The list of filtered items.
   * @returns {void}
   */
  async function loadFilteredFilesPec(filteredList) {
    setFolderContents(
      filteredList
        .map((item) => {
          return {
            id: item.folder_item_id,
            attributes: {
              id: item.folder_item_id,
              displayName: item.item_name,
            },
          };
        })
        .sort((a, b) => {
          return parseInt(a.attributes) - parseInt(b.attributes);
        })
    );

    setItemsMetadata(filteredList);
  }
  /**
   ** Handles changes in the form fields for the 'pec' project based on the specified method send by FormAutoComplete.
   ** Updates the form object and triggers relevant actions accordingly.
   *
   * @param {string} field - The field being modified in the form.
   * @param {string} method - The method indicating the type of change (e.g., "onChange", "onKeyUp").
   * @param {Object} e - The event object (default empty object).
   * @param {string} nv - The new value (default empty string).
   * @param {string} reason - The reason for the change (default empty string).
   * @returns {void}
   */
  function changeFormPec(field, method, e = {}, nv = "", reason = "") {
    switch (method) {
      case "onChange":
        form[field] = nv.toUpperCase();
        if (reason === "selectOption") form[field + "Selected"] = true;
        itemSelectedPec(form, field, nv.toUpperCase());
        break;
      case "onKeyUp":
        if (e.key === "Enter") filterFolderContentsPec(form);
        else form[field] = nv.toUpperCase();
        break;
      case "onChangeTextfield":
        if (nv === "") {
          delete form[field];
          delete form[field + "Searched"];
        } else {
          form[field] = nv.toUpperCase();
          form[field + "Searched"] = true;
        }

        if (nv.length === 0 || nv.length > 3)
          itemSelectedPec(form, field, nv.toUpperCase());
        break;
      default:
        break;
    }
  }

  //#region ose methods
  /**
   * Loads data for the 'ose' project. Invokes 'loadFieldOse' method for each field.
   *
   * @param {string} method - The method triggering the data load.
   * @returns {void}
   */
  async function loadDataOse(method = "") {
    setLoading(true);
    for (const property in itemsMetadataOse) {
      let fieldCaptalize =
        "previousPage" + property[0].toUpperCase() + property.slice(1);
      window[fieldCaptalize] = -1;

      await loadFieldOse("ose", method, property);
    }
    setLoading(false);
  }
  /**
   * Handles the 'Clear' action for the 'ose' project. Clears form, folder contents, and triggers data reload.
   *
   * @returns {void}
   */
  async function handleClearOse() {
    await loadDataOse("onClear");
    setForm({});
    clearFolderContents();
    loadingTimeout(7000);
  }
  /**
   * Loads data for a specific field in the 'ose' project.
   *
   * @param {string} project - The project name ('ose').
   * @param {string} method - The method triggering the data load.
   * @param {string} field - The field for which data needs to be loaded.
   * @param {number} page - The page number for paginated loading (default is 0).
   * @returns {void}
   */
  async function loadFieldOse(project, method, field, page = 0) {
    try {
      let fieldCaptalize =
        "previousPage" + field[0].toUpperCase() + field.slice(1);
      if (checkPermission(page, itemsMetadataOse[field], method)) {
        let column = formatFieldToColumn(field);
        let result = await getFilter(project, column, page);

        window[fieldCaptalize] = page;

        if (result.data !== itemSelectedOse[field])
          itemsMetadataOse[field] =
            method === "onClear" || method === "onLoad"
              ? result.data
              : itemsMetadataOse[field].concat(result.data);
      }
    } catch (ex) {
      loadFieldOse(project, method, field, page);
    }
  }

  /**
   * Handles the selection or input of an item in the form auto-complete or the input field.
   * Invokes a conditional filter for the 'ose' project based on the selected item or input value.
   *
   * @param {Object} form - The form object containing field-value pairs.
   * @param {string} field - The field being selected or typed into.
   * @param {string} value - The selected item or typed input value.
   * @param {number} page - The page number for paginated loading (default is 0).
   * @returns {void}
   */
  async function itemSelectedOse(form, field, value, page = 0) {
    setLoading(true);
    let fieldCaptalize =
      "previousPage" + field[0].toUpperCase() + field.slice(1);
    window[fieldCaptalize] = -1;

    for (const property in itemsMetadataOse) {
      let column = formatFieldToColumn(property);
      let result = await getFilter("ose", column, page, form);
      addItemsOse(property, result.data, page);
    }
    setLoading(false);
  }
  /**
   * Adds items to the metadataEsgoto for a specific field in the 'ose' project based on a conditional check.
   *
   * @param {string} field - The field for which items are being added.
   * @param {Array} items - The items to be added to the metadata.
   * @param {number} page - The page number for paginated loading.
   * @param {string} method - The method triggering the addition (default is 'onFilter').
   * @returns {void}
   */
  function addItemsOse(field, items, page, method = "onFilter") {
    if (checkPermission(page, itemsMetadataOse[field], method))
      itemsMetadataOse[field] = items;
  }
  /**
   * Filters folder contents in the 'ose' project based on the provided form filters.
   ** Displays success or error alerts based on the search results.
   *
   * @param {Object} form - The form object containing field-value pairs for filtering.
   * @returns {void}
   */
  async function filterFolderContentsOse(form) {
    setLoading(true);

    if (Object.keys(form).length === 0) {
      setFolderContents([]);
      setLoading(false);
      displayErrorAlert(
        "É obrigatório o preenchimento de ao menos um filtro-OSE"
      );
      return;
    }

    var filteredList = await getSearch("ose", form);

    loadFilteredFilesOse(filteredList.data).then(() => {
      setLoading(false);
      if (filteredList.data.length > 0)
        displaySuccessAlert("Pesquisa realizada com sucesso");
      else displayErrorAlert("Não existem registros com esses filtros");
    });
  }

  /**
   * Sends the filtered files from the 'ose' project based on the list provided in filterFolderContentsOse'
   ** The filtered files will go to itemsMetadata, where they will be displayed in results on the screen, after the user clicks 'Pesquisar'.
   *
   * @param {Array} filteredList - The list of filtered items.
   * @returns {void}
   */
  async function loadFilteredFilesOse(filteredList) {
    setFolderContents(
      filteredList
        .map((item) => {
          return {
            id: item.folder_item_id,
            attributes: {
              id: item.folder_item_id,
              displayName: item.item_name,
            },
          };
        })
        .sort((a, b) => {
          return parseInt(a.attributes) - parseInt(b.attributes);
        })
    );

    setItemsMetadata(filteredList);
  }

  /**
   ** Handles changes in the form fields for the 'OSE' project based on the specified method send by FormAutoComplete.
   ** Updates the form object and triggers relevant actions accordingly.
   *
   * @param {string} field - The field being modified in the form.
   * @param {string} method - The method indicating the type of change (e.g., "onChange", "onKeyUp").
   * @param {Object} e - The event object (default empty object).
   * @param {string} nv - The new value (default empty string).
   * @param {string} reason - The reason for the change (default empty string).
   * @returns {void}
   */
  function changeFormOse(field, method, e = {}, nv = "", reason = "") {
    switch (method) {
      case "onChange":
        form[field] = nv.toUpperCase();
        if (reason === "selectOption") form[field + "Selected"] = true;
        itemSelectedOse(form, field, nv.toUpperCase());
        break;
      case "onKeyUp":
        if (e.key === "Enter") filterFolderContentsOse(form);
        else form[field] = nv.toUpperCase();
        break;
      case "onChangeTextfield":
        if (nv === "") {
          delete form[field];
          delete form[field + "Searched"];
        } else {
          form[field] = nv.toUpperCase();
          form[field + "Searched"] = true;
        }

        if (nv.length === 0 || nv.length > 3)
          itemSelectedOse(form, field, nv.toUpperCase());
        break;
      default:
        break;
    }
  }
  /**
   * Tab Control in the Arquivo tecnico Project
   **  Values receives by Arquivo Tecnico Form
   * @param {*} index - index id: (0) for PEC (1) for OSE
   * @returns
   */
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  /**
   * Request for the backend to return data to the log table based on filters selected by the user or without any filter
   * @param {*} form  fields of filters (Usuário, ação, empresa, data, projeto)
   * @returns Array of objects contains: id, name, company, actions, project , file name,file id, date and hour
   */
  async function loadItemsTableLogs(form) {
    var result = await getResultLogs(form);
    return result;
  }

  /**
   * Request for the backend to return data to the fields of filter of administration screen
   *
   * @returns Objects of Arrays contains: name, company, actions, project.
   * @returns All fields have duplicate values removed
   */
  async function loadFilterTable() {
    setLoading(true);
    let filteredList = await get_filterAdm();

    let allFilters = {
      resultName: [],
      resultCompany: [],
      resultProject: [],
      resultAction: [],
    };

    let resultName = filteredList.map((item) => {
      return item.user_full_name ? item.user_full_name : "";
    });

    let resultCompany = filteredList.map((item) => {
      return item.user_company ? item.user_company : "";
    });

    let resultAction = filteredList.map((item) => {
      return item.user_action ? item.user_action : "";
    });

    let resultProject = filteredList.map((item) => {
      return item.project ? item.project : "";
    });

    allFilters.resultName = resultName
      .filter((item) => item !== "")
      .sort((a, b) => a.localeCompare(b));

    allFilters.resultCompany = resultCompany
      .filter((item) => item !== "")
      .sort((a, b) => a.localeCompare(b));

    allFilters.resultAction = resultAction
      .filter((item) => item !== "")
      .sort((a, b) => a.localeCompare(b));

    allFilters.resultProject = resultProject
      .filter((item) => item !== "")
      .sort((a, b) => a.localeCompare(b));

    setLoading(false);
    return allFilters;
  }

  /**
   * Generates an avatar configuration object based on the given name.
   * @param {string} name - The name to generate the avatar for.
   * @returns {Object} - The avatar configuration object.
   */
  function stringAvatar(name) {
    if (name)
      // If name is not empty
      return {
        sx: {
          bgcolor: stringToColor(name), // Set background color based on name
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`, // Set initials based on name
      };
  }

  /**
   * Generates a color based on the given string.
   * @param {string} string - The string to generate the color from.
   * @returns {string} - The generated color code.
   */
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  return (
    <AecFolderContext.Provider
      value={{
        //administracao
        itemsTableLogs,
        loadItemsTableLogs,
        loadFilterTable,
        //general
        itemsMetadata,
        handleHelp,
        onNextDocument,
        onPreviousDocument,
        getUsersPermissions,
        projectsPermissions,
        folderContents,
        selectedFile,
        setSelectedFile,
        selectedFolders,
        setSelectedFolders,
        downloadAllFiles,
        LogTrigger,
        projectName,
        clearFolderContents,
        prepareViewer,
        selectedFileDownloadUrl,
        link,
        getUser,
        userData,
        isAdministrationScreen,
        setIsAdministrationScreen,
        stringAvatar,
        //agua
        form,
        changeFormAgua,
        itemsMetadataAgua,
        handleClearAgua,
        loadFieldAgua,
        filterFolderContentsAgua,

        //Esgoto
        changeFormEsgoto,
        itemsMetadataEsgoto,
        handleClearEsgoto,
        loadFieldEsgoto,
        filterFolderContentsEsgoto,

        //PEC
        changeFormPec,
        itemsMetadataPec,
        handleClearPec,
        loadFieldPec,
        filterFolderContentsPec,

        //OSE
        changeFormOse,
        itemsMetadataOse,
        handleClearOse,
        loadFieldOse,
        filterFolderContentsOse,

        //tabs
        selectedTab,
        setSelectedTab,
        a11yProps,
        handleChangeTab,
      }}
    >
      {children}
    </AecFolderContext.Provider>
  );
};
