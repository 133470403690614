import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

/**
    Function responsible for generating and downloading the PDF with the data provided
  * @param {Array} datas - Data to be displayed in the PDF
  * @param {Object} filter - Filters applied to data
  * @param {Array} dateRange - Selected date range
 */
export default function dadosToPdf(datas, filter, dateRange) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();
    const formattedDateRangeDe = dateRange[0].toLocaleDateString();
    const formattedDateRangeAte = dateRange[1].toLocaleDateString();


    const reportTitle = [
        {
            text: `${formattedDate} - ${formattedTime} - Visual BIM `,
            fontSize: 5,
            alignment: 'justify',
            margin: [5, 5, 0, 0] //left, top, rigth, bottom 
        },
        {
            text: 'Consulta de Histórico de Usuários',
            alignment: 'justify',
            fontSize: 15,
            bold: true,
            margin: [185, 10, 0, 45],//left, top, rigth, bottom 
        }];

    const dados = datas.map((rows) => {
        return [
            { text: rows.Nome, fontSize: 8, margin: [0, 1, 0, 1] },
            { text: rows.Empresa, fontSize: 8, margin: [0, 1, 0, 1] },
            { text: rows.Acao, fontSize: 8, margin: [0, 1, 0, 1] },
            { text: rows.Projeto, fontSize: 8, margin: [0, 1, 0, 1] },
            { text: rows.Arquivo, fontSize: 8, margin: [0, 1, 0, 1] },
            { text: rows.Identificador.substr(28, 50), fontSize: 8, margin: [0, 1, 0, 1] },
            { text: rows.Data, fontSize: 8, margin: [0, 1, 0, 1] },
            { text: rows.Hora.substr(0, 5), fontSize: 8, margin: [0, 1, 0, 1] },
        ]
    })

    const detais = [
        {
            text: 'Filtros:', margin: [0, 1, 0, 8],
            bold: true,
            fontSize: 10,
        },
        {
            style: 'tableExample',
            table: {
                headerRows: 1,
                widths: ['*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: 'Período:', style: 'tableHeader', fontSize: 8, bold: true, },
                        { text: 'Usuário:', style: 'tableHeader', fontSize: 8, bold: true, },
                        { text: 'Empresa:', style: 'tableHeader', fontSize: 8, bold: true, },
                        { text: 'Ação:', style: 'tableHeader', fontSize: 8, bold: true, },
                        { text: 'Projeto:', style: 'tableHeader', fontSize: 8, bold: true, },
                    ],
                    [
                        { text: `${formattedDateRangeDe} - ${formattedDateRangeAte}`, style: 'tableHeader', fontSize: 8 },
                        { text: filter.userName, style: 'tableHeader', fontSize: 8 },
                        { text: filter.userCompany, style: 'tableHeader', fontSize: 8, bold: true, },
                        { text: filter.userAction, style: 'tableHeader', fontSize: 8 },
                        { text: filter.project, style: 'tableHeader', fontSize: 8 },
                    ],
                ]
            },
            layout: 'noBorders',
            margin: [0, 0, 0, 15]
        },
        {
            style: 'tableExample',
            table: {
                headerRows: 1,
                widths: [79, 60, 83, 40, 80, 80, 45, 25],
                body: [
                    [
                        { text: 'Nome', style: 'tableHeader', fontSize: 8, bold: true, },
                        { text: 'Empresa', style: 'tableHeader', fontSize: 8, bold: true, },
                        { text: 'Ação', style: 'tableHeader', fontSize: 8, bold: true, },
                        { text: 'Projeto', style: 'tableHeader', fontSize: 8, bold: true, },
                        { text: 'Arquivo', style: 'tableHeader', fontSize: 8, bold: true, },
                        { text: 'ID arquivo', style: 'tableHeader', fontSize: 8, bold: true, },
                        { text: 'Data', style: 'tableHeader', fontSize: 8, bold: true, },
                        { text: 'Hora', style: 'tableHeader', fontSize: 8, bold: true, }
                    ],
                    ...dados
                ]
            },
            layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? ((rowIndex === 0) ? '#eefaff;' : null) : null;
                }
            },
        }];

    function Rodape(currentPage, pageCount) {
        return [{
            text: 'FF SOLUTIONS                                                                                                       ' + currentPage + ' / ' + pageCount,
            aligment: 'left',
            fontSize: 9,
            margin: [265, 10, 10, 0],//left, top, rigth, bottom 
        }]
    }
    const docDefinitions = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],

        header: [reportTitle],
        content: [detais],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitions).download();

}