import React from 'react';
import styles from './index.module.css';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {Select as MuiSelect} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

/**
 * Generic select component.
 * @param {string} labelId - The ID for the input label, used for accessibility.
 * @param {string} id - The ID for the select input element.
 * @param {string} value - The current value of the select input.
 * @param {string} labelText - The text to display for the input label.
 * @param {function} onChange - Callback function triggered when the selected value changes.
 * @param {Array<{value: string, label: string}>} options - An array of options to display in the dropdown, where each option is an object with `value` and `label` properties.
 */
const Select = ({ labelId, id, value, labelText, onChange, options, disabled }) => {
  return (<div className={styles.select}>
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id={labelId}>{labelText}</InputLabel>
      <MuiSelect labelId={labelId} id={id} value={value} label={labelText} onChange={onChange}>
        {options.map((option, i) => {
          return <MenuItem key={i} value={option.value}>{option.label}</MenuItem>
        })};
      </MuiSelect>
    </FormControl>
  </div>)
}

export default Select;