import React, { useState, useEffect, useContext, useRef, Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AecFolderContext } from "../../../Provider/AecFolderProvider";
import { Button } from "@mui/material";
import "./Administracao.css";
import * as XLSX from "xlsx";
import { DateRangePicker } from "rsuite";
import FormAutocomplete from "../Autocomplete";
import dadosToPdf from "./pdfDowlaod"
import { LoadingContext } from "../../../Provider/LoadingProvider";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const FormAdministracao = () => {
    const { setLoading } = useContext(LoadingContext);


    const { loadItemsTableLogs, loadFilterTable } = useContext(AecFolderContext);
    const [userName, setUserName] = useState([]);
    const [userProject, setUserProject] = useState([]);
    const [userCompany, setUserCompany] = useState([]);
    const [userAction, setAction] = useState([]);
    const [form, setForm] = useState({})

    // States for random keys used to force selection components to update
    const [userNameKey, setUserNameKey] = useState(Math.random())
    const [userProjectKey, setUserProjectKey] = useState(Math.random())
    const [userCompanyKey, setUserCompanyKey] = useState(Math.random())
    const [userActionKey, setActionKey] = useState(Math.random())

    const gridRef = useRef(null);
    // State to store the date range selected by the user
    const [data, setData] = useState([]);
    const [dateRange, setDateRange] = useState(getDefaultDateRange);

    // Function to fetch table data based on selected filters
    const fetchData = async () => {
        setLoading(true);
        const result = await loadItemsTableLogs(form);
        const resultFilter = await loadFilterTable();

        setData(
            result.data.map((item) => ({
                id: item[0],
                Nome: item[1],
                Empresa: item[2],
                Acao: item[3],
                Projeto: item[4],
                Arquivo: item[5],
                Identificador: item[6],
                Data: item[7],
                Hora: item[8],
            }))
        );
        // Update filter states with the data obtained
        setUserName(resultFilter.resultName);
        setUserProject(resultFilter.resultProject);
        setUserCompany(resultFilter.resultCompany);
        setAction(resultFilter.resultAction);
        setLoading(false);
    }

    // Effect to fetch table data whenever the filter form is changed
    useEffect(() => {
        fetchData();
    }, [JSON.stringify(form)]);

    const columns = [
        { headerName: "ID", field: "id", flex: 0.5, minWidth: 75 },
        { headerName: "Nome", field: "Nome", flex: 1.2, minWidth: 150 },
        { headerName: "Empresa", field: "Empresa", flex: 1, minWidth: 100 },
        { headerName: "Ação", field: "Acao", flex: 1.4, minWidth: 100 },
        { headerName: "Projeto", field: "Projeto", flex: 0.8, minWidth: 75 },
        { headerName: "Arquivo", field: "Arquivo", flex: 1.5, minWidth: 150 },
        { headerName: "Identificador", field: "Identificador", flex: 2, minWidth: 200 },
        { headerName: "Data", field: "Data", flex: 0.5, minWidth: 100 },
        { headerName: "Hora", field: "Hora", flex: 0.5, minWidth: 100 },
    ];
    // Function to call the PDF download with the data from the current table
    const handleDownloadPDF = async () => {
        dadosToPdf(data, form, dateRange)
    };

    // Function to call the Excel download with the data from the current table
    const handleDownloadExcel = () => {
        const filteredData = data;
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        saveExcelFile(excelBuffer, 'historico-usuarios.xlsx');
    };

    function saveExcelFile(buffer, filename) {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }

    // Function to get the default date range (30 days ago to today)
    function getDefaultDateRange() {
        const currentDate = new Date();
        const thirtyDaysAgo = new Date(currentDate);
        thirtyDaysAgo.setDate(currentDate.getDate() - 30);
        return [thirtyDaysAgo, currentDate];
    }

    return (
        <>
            <div className="adm-header">
                <h4>Consulta de Histórico de Usuários</h4>
            </div>

            <div className="fields-container-adm">
                <div style={{ display: "grid", }}>
                    <span style={{ marginRight: "5px" }}>Período:</span>
                    <DateRangePicker
                        className="data-range-logs"
                        format="dd/MM/yyyy"
                        value={dateRange}
                        onOk={(date) => {
                            setDateRange(date);
                            setForm({ ...form, data: date })
                        }
                        }
                    />
                </div>

                <div style={{ display: "grid", }}>
                    <span style={{ marginRight: "5px" }}>Usuário:</span>
                    <FormAutocomplete
                        key={userNameKey}
                        id="usuario"
                        options={userName}
                        className="auto-complete-logs"
                        onChange={(event) => {
                            setForm({ ...form, userName: event.target.innerText });
                        }}
                        onChangeTextfield={(event) => {
                            if (event.target.value === "") {
                                delete form['userName']
                                setUserNameKey(Math.random())
                                fetchData();
                            }
                        }}
                    />
                </div>

                <div style={{ display: "grid", }}>
                    <span style={{ marginRight: "5px" }}>Organização:</span>
                    <FormAutocomplete
                        key={userCompanyKey}
                        id="Organização"
                        options={userCompany}
                        className="auto-complete-logs"
                        onChange={(event) => {
                            setForm({ ...form, userCompany: event.target.innerText });
                        }}
                        onChangeTextfield={(event) => {
                            if (event.target.value === "") {
                                setUserCompanyKey(Math.random())
                                delete form['userCompany']
                                fetchData();
                            }
                        }}
                    />
                </div>

                <div style={{ display: "grid", }}>
                    <span style={{ marginRight: "5px" }}>Projeto:</span>
                    <FormAutocomplete
                        key={userProjectKey}
                        id="project"
                        options={userProject}
                        className="auto-complete-logs"
                        onChange={(event) => {
                            setForm({ ...form, project: event.target.innerText });
                        }}
                        onChangeTextfield={(event) => {
                            if (event.target.value === "") {
                                delete form['project']
                                setUserProjectKey(Math.random())
                                fetchData();
                            }
                        }}

                    />
                </div>

                <div style={{ display: "grid", }}>
                    <span style={{ marginRight: "5px" }}>Ação:</span>
                    <FormAutocomplete
                        key={userActionKey}
                        id="Ação"
                        options={userAction}
                        className="auto-complete-logs"
                        onChange={(event) => {
                            setForm({ ...form, userAction: event.target.innerText });
                        }}
                        onChangeTextfield={(event) => {
                            if (event.target.value === "") {
                                delete form['userAction']
                                setActionKey(Math.random())
                                fetchData();
                            }
                        }}
                    />
                </div>
            </div>

            <div className="buttons-container">
                <Button variant="outlined" onClick={handleDownloadPDF}>
                    <FileDownloadOutlinedIcon style={{ marginRight: "8px" }} />
                    Download PDF
                </Button>

                <Button variant="outlined" onClick={handleDownloadExcel}>
                    <FileDownloadOutlinedIcon style={{ marginRight: "8px" }} />
                    Download Excel
                </Button>

            </div>

            <div className="class-table" ref={gridRef}>
                <DataGrid
                    id="class-table"
                    rows={data}
                    columns={columns}
                    pageSize={20}
                    rowHeight={35} // Defina o valor de altura desejado
                    pagination
                    disableColumnFilter
                />
            </div>
            <hr></hr>

            {/* {print ? <AllDataGrid/> : ""} */}


        </>
    );
};

export default FormAdministracao;
