import React from 'react';

import Alert from '@mui/material/Alert';

/**
 * Generic paragraph component.
 * @param {string} content - The text content of the paragraph.
 * @param {string} [id] - The id attribute of the paragraph.
 */
const Paragraph = ({ content, id }) => {
  return (
    <Alert severity="info" id={id}>
      {content}
    </Alert>
  )
}

export default Paragraph;