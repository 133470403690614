import apiRequest from '../apiRequest';

export async function saveFile(downloadUrl, fileName, token) {
    var url = process.env.REACT_APP_SERVER_API_BASE_URL + 'saveFile?downloadUrl=' + downloadUrl + '&fileName=' + fileName;

    var result = apiRequest.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

    return result;
}

export async function compressFiles(filesList, token) {
    try {

        var url = process.env.REACT_APP_SERVER_API_BASE_URL + 'compress';
        var result = await apiRequest.post(url, { files: filesList }, { headers: { Authorization: `Bearer ${token}` } });

        if (result.status !== 200)
            throw new Error();

        return result.data;
    } catch (e) {
        throw e;
    }
}

