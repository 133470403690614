import { Autocomplete, TextField, styled} from "@mui/material";

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiOutlinedInput-root": {
        borderRadius: 4,
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        color: "#B6C7D4",
        fontSize: 16,
    },
    "& .MuiInputLabel-root": {
        color: "#B6C7D4",
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
        color: "var(--input-font-color)",
    },
    "& .MuiAutocomplete-inputRoot": {
        color: "var(--input-font-color)",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#B6C7D4",
        },
    },
});

const autocompleteStyle = {
    fontSize: 14,
};

const FormAutocomplete = ({ options, label, onChange, onKeyDown, onKeyUp, onChangeTextfield, filterOptions, listboxProps,className }) => {
    return (
        <StyledAutocomplete
            style={autocompleteStyle}
            size="small"
            onChange={onChange}
            freeSolo
            disableClearable
            options={options}
            filterOptions={filterOptions}
            ListboxProps={listboxProps}
            className={className}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    onChange={onChangeTextfield}
                    label={label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        type: "search",
                    }}
                />
            )}
        />
    );
};

export default FormAutocomplete;
