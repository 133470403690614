import { Button, Card, CardContent, IconButton } from "@mui/material";
import doc from "../../assets/images/doc.png";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import { useContext, useEffect, useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import "./DocumentViewer.css";
import ExcelViewer from "../ExcelViewer";

const visualBimViewerContainer = {
    display: "flex",
    displayDirection: "column-reverse",
    justifyContent: "center",
};

const headerStyle = {
    display: "flex",
    alignItems: "center",
    gap: 10,
};

const imageStyle = {
    height: 30,
};

const titleStyle = {
    color: "#718797",
    fontSize: 14,
};

const buttonStyle = {
    textTransform: "none",
    boxShadow: "none",
};

function DocumentViewer({ metadataContainerRef }) {
    const {
        selectedFileDownloadUrl,
        selectedFile,
        onNextDocument,
        onPreviousDocument,
        folderContents,
        link,
        LogTrigger,
        projectName
    } = useContext(AecFolderContext);

    const [selectedFileKey, setSelectedFileKey] = useState(Math.random());

   async function onDownload() {
        await LogTrigger("DOWLOAD",selectedFile.attributes.displayName,selectedFile.attributes.id,projectName);

        window.location.href =
            process.env.REACT_APP_SERVER_API_BASE_URL +
            "download?fileName=" +
            selectedFile.attributes.displayName;
    }

    async function onLink() {
        
        await LogTrigger("VISUALIZAÇÃO VIA ACC",selectedFile.attributes.displayName,selectedFile.attributes.id,projectName);
        window.open(link, '_blank');
    }
    useEffect(() => {
        setSelectedFileKey(Math.random());
    }, [selectedFile]);

    const name = selectedFile.attributes.displayName.toUpperCase();

    const isVisible =
        name.endsWith(".PDF") ||
        name.endsWith(".JPG") ||
        name.endsWith(".TIF") ||
        name.endsWith(".DWG");

    const isZip = name.endsWith(".ZIP");

    return (
        <>
            <Card className="card-header">
                <div style={headerStyle}>
                    <img
                        style={imageStyle}
                        src={doc}
                        alt="Visualização do documento"
                    />
                    <label style={titleStyle}>Visualização do documento</label>
                </div>
                {selectedFileDownloadUrl ? (
                    <>
                        <div className="navigation">
                            <IconButton
                                aria-label="back"
                                color="primary"
                                disabled={
                                    folderContents.indexOf(selectedFile) < 1
                                }
                                size="small"
                                onClick={onPreviousDocument}
                            >
                                <ArrowBackIosNewOutlinedIcon />
                            </IconButton>
                            <IconButton
                                aria-label="forward"
                                color="primary"
                                disabled={
                                    folderContents.length <=
                                    folderContents.indexOf(selectedFile) + 1
                                }
                                size="small"
                                onClick={onNextDocument}
                            >
                                <ArrowForwardIosOutlinedIcon />
                            </IconButton>
                        </div>
                        <div className="container-buttons">
                            {!isZip && (
                            <Button
                                onClick={onLink}
                                style={buttonStyle}
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<ArrowOutwardIcon />}
                            >
                                Visualizar no ACC
                            </Button>
                            )}
                            <Button
                                onClick={ onDownload }
                                style={buttonStyle}
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<FileDownloadOutlinedIcon />}
                            >
                                Baixar arquivo
                            </Button>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </Card>
            <Card className="card-viewer">
                <CardContent key={selectedFileKey}>
                    {isVisible ? (
                        <div
                            id="forgeViewer"
                            style={visualBimViewerContainer}
                        />
                    ) : isZip ? (
                        <>
                            <div className="view-excel">
                                <p className="sub-text">
                                    Clique no botão abaixo para fazer o download
                                    do arquivo e visualizá-lo localmente
                                </p>
                                <Button
                                    onClick={onDownload}
                                    style={buttonStyle}
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                >
                                    Download
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="view-excel">
                                <p className="sub-text">
                                    Clique no botão abaixo para visualizar o
                                    arquivo
                                </p>
                                <a
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button
                                        style={buttonStyle}
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                    >
                                        Visualizar
                                    </Button>
                                </a>
                            </div>
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    );
}

export default DocumentViewer;
