import { ThemeProvider, createTheme } from "@mui/material";
import {
    createContext,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";

export const ColorModeContext = createContext({});

export const ColorModeProvider = ({ children }) => {
    const getUserTheme = () => {
        const isUserThemeDark = window.matchMedia("(prefers-color-scheme: dark)")?.matches;

        return isUserThemeDark ? 'dark' : 'light';
    };

    const [mode, setMode] = useState(() =>
        localStorage.getItem("theme") ?? getUserTheme()
    );

    useLayoutEffect(() => {
        localStorage.setItem("theme", mode);

        if (mode === "light") {
            document.documentElement.classList.remove("dark-mode");
            document.documentElement.classList.add("light-mode");
        } else {
            document.documentElement.classList.remove("light-mode");
            document.documentElement.classList.add("dark-mode");
        }
    }, [mode]);

    const toggleColorMode = () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    };

    function getPreviousSelectedTheme() {
        const theme = localStorage.getItem("theme");
        const hasTheme = !!theme;

        if (hasTheme) {
            setMode(theme);
        }
    }

    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    function getDesignTokens(mode) {
        return {
            palette: {
                mode
            }
        };
    }

    return (
        <ColorModeContext.Provider
            value={{
                toggleColorMode,
                getDesignTokens,
                getPreviousSelectedTheme,
            }}
        >
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ColorModeContext.Provider>
    );
};
