import { getTokenAuthenticate } from "../AuthService";
import apiRequest from "../apiRequest";

/**
 * Fetches the contents of folders.
 * @returns {object} - Folder contents fetched from the backend.
 */
export async function getFoldersContents() {
  var url = process.env.REACT_APP_FLASK_BACKEND + "folder_items";
  var result = await apiRequest.get(url);
  return result;
}
/**
 * Fetches the root folders.
 * @returns {object} - Root folders fetched from the backend.
 */
export async function getRootFolders() {
  var url = process.env.REACT_APP_FLASK_BACKEND + "root_folders";
  var result = await apiRequest.get(url);
  return result;
}

/**
 * Fetches filter data based on the specified parameters.
 * @param {string} project - The project ID or name.
 * @param {string} column - The column to filter.
 * @param {number} page - The page number of pagination
 * @param {object} form - The form data.
 * @param {number} limit - The limit of results to fetch the pagination.
 * @returns {object} - Filter data fetched from the backend.
 */
export async function getFilter(
  project,
  column,
  page = 0,
  form = {},
  limit = 200
) {
  var url = process.env.REACT_APP_FLASK_BACKEND + "get_filter";
  var body = {
    column: column,
    page: page,
    form: form,
    limit: limit,
    project: project,
  };

  var result = await apiRequest.post(url, body);
  return result;
}

/**
 * Fetches search data based on the specified parameters.
 * @param {string} project - The project ID or name.
 * @param {object} form - The form data.
 * @returns {object} - Search data fetched from the backend.
 */
export async function getSearch(project, form = {}) {
  var url = process.env.REACT_APP_FLASK_BACKEND + "get_search";

  if (project) {
    var body = {
      form: form,
      project: project,
    };
  }

  var result = await apiRequest.post(url, body);
  return result;
}

//GENERAL

/**
 * Retrieves project permissions for the user.
 * @returns {object} - Project permissions data fetched from the API.
 */
export async function getProjectsPermissions() {
  let token = localStorage.getItem("access_token");

  if (!!token) {
    let url =
      process.env.REACT_APP_API_BASE_URL +
      "project/v1/hubs/" +
      process.env.REACT_APP_SABESP_HUB_ID +
      "/projects";
    let result = await apiRequest.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  }
}

/**
 * Retrieves access permissions for a user based on their email address.
 * @param {string} email - The email address of the user.
 * @returns {object} - Access permissions data for the user fetched from the API.
 */
export async function getAccessPermissions(email) {
  let company = email.split("@")[1];
  email = email.replace("@", "%40");
  const requestToken = await getTokenAuthenticate();

  const token = requestToken.data.access_token;

  let result;

  try {
    if (company === "ff.solutions") {
      // Concatenando o parâmetro account_id à URL usando interpolação de strings
      let url = `${process.env.REACT_APP_API_BASE_URL}hq/v1/accounts/${process.env.REACT_APP_FF_HUB_ID}/users/search?email=${email}`;
      result = await apiRequest.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
      let url = `${process.env.REACT_APP_API_BASE_URL}hq/v1/accounts/${process.env.REACT_APP_SABESP_HUB_ID}/users`;
      result = await apiRequest.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
    return result.data;
  } catch (e) {
    console.log("erro ao verificar permissoes de acesso:" + e);
  }
}

/**
 * Retrieves the stream of an item from a specified URL.
 * @param {string} url - The URL of the item's stream.
 * @returns {object} - The stream data of the item fetched from the URL.
 */
export async function getItemStream(url) {
  var token = localStorage.getItem("access_token");

  var result = await apiRequest.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return result;
}

/**
 * Retrieves information about a specific file from the Autodesk Forge API.
 * @param {string} fileId - The ID of the file for which information is to be retrieved.
 * @param {string} selectedProject - The ID of the selected project.
 * @returns {object} - Information about the specified file fetched from the API.
 */
export async function getFileInfo(fileId, selectedProject) {
  var url =
    process.env.REACT_APP_API_BASE_URL +
    "data/v1/projects/" +
    selectedProject +
    "/items/" +
    fileId;
  var token = localStorage.getItem("access_token");

  var result = await apiRequest.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return result;
}

/**
 * Retrieves information about a list of files from the Autodesk Forge API.
 * @param {Array} filesList - List of files for which information is to be retrieved.
 * @returns {object} - Information about the specified files fetched from the API.
 */
export async function getListFileInfo(filesList) {
  var url =
    process.env.REACT_APP_API_BASE_URL +
    "data/v1/projects/" +
    process.env.REACT_APP_PROJECT_ID +
    "/commands";
  var token = localStorage.getItem("access_token");
  var data = {
    jsonapi: {
      version: "1.0",
    },
    data: {
      type: "commands",
      attributes: {
        extension: {
          type: "commands:autodesk.core:ListItems",
          version: "1.1.0",
          data: {
            includePathInProject: false,
          },
        },
      },
      relationships: {
        resources: {
          data: filesList.map((item) => {
            return {
              type: "items",
              id: item.itemUrn,
            };
          }),
        },
      },
    },
  };

  var result = await apiRequest.post(url, data, {
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${token}`,
    },
  });

  return result;
}

/**
 * Retrieves user data from the Autodesk user profile API.
 * @returns {object} - The user data fetched from the API.
 */
export async function getUserData() {
  var token = localStorage.getItem("access_token");
  var url = "https://api.userprofile.autodesk.com/userinfo";
  var response = await apiRequest.post(url, new URLSearchParams(), {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

/**
 * Retrieves logs based on the provided form data.
 * @param {object} form - The form data used for filtering logs.
 * @returns {object} - The result containing logs based on the provided filters.
 */
export async function saveLog(userData) {
  var url = process.env.REACT_APP_FLASK_BACKEND + "save_log";
  var body = userData;
  await apiRequest.post(url, body);
}

/**
 * Retrieves logs based on the provided form data.
 * @param {object} form - The form data used for filtering logs.
 * @returns {object} - The result containing logs based on the provided filters.
 */
export async function getResultLogs(form) {
  var url = process.env.REACT_APP_FLASK_BACKEND + "get_logs";
  var body = {
    fullName: form.userName ? form.userName : null,
    company: form.userCompany ? form.userCompany : null,
    userAction: form.userAction ? form.userAction : null,
    project: form.project ? form.project : null,
    data: form.data ? form.data : null,
  };
  var result = await apiRequest.post(url, body); // Alterado para usar o método 'get'
  return result;
}

/**
 * Retrieves options and data for filter fields.
 * @returns {object} - The data containing options for filter fields.
 */
export async function get_filterAdm() {
  var url = process.env.REACT_APP_FLASK_BACKEND + "get_filterAdm";
  var result = await apiRequest.post(url); // Alterado para usar o método 'get'
  return result.data;
}
