import Header from "../../components/Header";
import Footer from "../../containers/footer";
import ListPages from "../../components/Home/ListPages";
import { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../Provider/ProjectProvider";

const Home = () => {  
    const { changeSelectedProject, Projects } = useContext(ProjectContext);

    useEffect(() => {
        changeSelectedProject(Projects.NONE);
    }, []);

   
  return (
        <>
            <Header />
            <ListPages />
            <Footer />
        </>       
    );
};

export default Home;
