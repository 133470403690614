import { useContext } from "react";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import { Card } from "@mui/material";
import fileImage from "../../assets/images/file.png";
import { AuthContext } from "../../Provider/AuthProvider";
import "./FileCard.css";

const infoStyle = {
    display: "flex",
    gap: 10,
    alignItems: "center",
};

const imageStyle = {
    height: 24,
};


const FileCard = ({ file, selectedMultiples }) => {
    const {
        selectedFile,
        setSelectedFile,
        prepareViewer,
        selectedFolders,
        setSelectedFolders,
    } = useContext(AecFolderContext);
    const { verifyToken} = useContext(AuthContext);

    const selectFile = async  () => {
        await verifyToken();
        if (selectedMultiples) {
            if (!selectedFolders.includes(file))
                setSelectedFolders((arr) => [...arr, file]);   
        } else {
            setSelectedFile(file);
            prepareViewer(file);
        }

    };

    const unselectFile = () => {
        if (selectedMultiples) 
            setSelectedFolders(selectedFolders.filter(item => item !== file));
        
    };

    return (selectedFile && selectedFile.id === file.id) || selectedFolders.includes(file) ?  (
        <Card className="file-card file-card-selected" onClick={unselectFile}>
            <div style={infoStyle}>
                <img style={imageStyle} src={fileImage} alt="Arquivo" />
                <label className="file-card-name file-card-name-selected">
                    {file.attributes.displayName}
                </label>
            </div>
        </Card>
    ) : (
        <Card className="file-card" onClick={selectFile}>
            <div style={infoStyle}>
                <img style={imageStyle} src={fileImage} alt="Arquivo" />
                <label className="file-card-name">{file.attributes.displayName}</label>
            </div>
        </Card>
    );
};

export default FileCard;
