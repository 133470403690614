import { Button, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const imageStyle = {
    width: 75,
};

const buttonPageStyle = {
    display: "flex",
    flexDirection: "column",
    minWidth: 170,
    alignItems: "center",
    gap: 15,
};

const ButtonPage = ({ image, text, color, onClick, tooltip }) => {
    return (
        <Tooltip title={tooltip}>
            <div style={buttonPageStyle}>
                <img style={imageStyle} src={image} alt={text} />
                <Button
                    onClick={onClick}
                    variant="outlined"
                    style={{
                        color: color,
                        borderColor: color,
                        textTransform: "none",
                    }}
                >
                    {text}
                </Button>
            </div>
        </Tooltip>
    );
};

export default ButtonPage;
