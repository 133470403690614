import React from 'react';
import styles from './index.module.css';

import Card from '../../molecules/Card';
import FormElement from '../../molecules/FormElement';

/**
 * Form box organism containing Card and FormElement molecules.
 */
const FormBox = () => {
  return (
    <div className={styles.formBox}>
      <Card/>
      <FormElement/>
    </div>
  )
}

export default FormBox;