import { useContext, useReducer, useState } from "react";
import { Alert, Snackbar, Link } from "@mui/material";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AecFolderContext } from "../../../Provider/AecFolderProvider";
import FormAutocomplete from "../Autocomplete";
import FormButton from "../Button";
import { AlertContext } from "../../../Provider/AlertProvider";
import "./Agua.css";

const FormAgua = () => {
    const {
        form,
        changeFormAgua,
        itemsMetadataAgua,
        handleClearAgua,
        loadFieldAgua,
        filterFolderContentsAgua,
    } = useContext(AecFolderContext);

    const { errorMessage, successMessage, showSuccessAlert, showErrorAlert } =
        useContext(AlertContext);

    const [formKey, setFormKey] = useState({
        codSabespKey: Math.random(),
        tituloKey: Math.random(),
        especialidadeKey: Math.random(),
        subAreaProjetoKey: Math.random(),
        areaProjetoKey: Math.random(),
        sistemaKey: Math.random(),
    });

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const handleClear = async () => {
        setFormKey({
            codSabespKey: Math.random(),
            tituloKey: Math.random(),
            especialidadeKey: Math.random(),
            subAreaProjetoKey: Math.random(),
            areaProjetoKey: Math.random(),
            sistemaKey: Math.random(),
        });
        await handleClearAgua();
        forceUpdate();
    };

    const changeForm = (field, method, e = {}, newValue = "", reason = "") => {
        changeFormAgua(field, method, e, newValue, reason);
        forceUpdate();
    };

    const onScrollField = async (currentTarget, field) => {
        if (
            Math.round(currentTarget.scrollTop) +
            Math.round(currentTarget.clientHeight) ===
            Math.round(currentTarget.scrollHeight)
        ) {
            await loadFieldAgua(
                "agua",
                "onScroll",
                field,
                Math.round(itemsMetadataAgua[field].length / 200)
            );
            forceUpdate();
        }
    };

    return (
        <>
            <div className="fields-container-agua">
                <FormAutocomplete
                    id="sistema"
                    key={formKey.sistemaKey}
                    options={itemsMetadataAgua.sistema}
                    label="Sistema Produtor de Água"
                    onChange={(e, nv, reason) =>
                        changeForm("sistema", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("sistema", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("sistema", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "sistema"),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="areaProjeto"
                    key={formKey.areaProjetoKey}
                    options={itemsMetadataAgua.areaProjeto}
                    label="Área"
                    onChange={(e, nv, reason) =>
                        changeForm("areaProjeto", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("areaProjeto", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("areaProjeto", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "areaProjeto"),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="subAreaProjeto"
                    key={formKey.subAreaProjetoKey}
                    options={itemsMetadataAgua.subAreaProjeto}
                    label="Sub-Área"
                    onChange={(e, nv, reason) =>
                        changeForm("subAreaProjeto", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("subAreaProjeto", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("subAreaProjeto", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(
                                currentTarget,
                                "subAreaProjeto"
                            ),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="especialidade"
                    key={formKey.especialidadeKey}
                    options={itemsMetadataAgua.especialidade}
                    label="Especialidade"
                    onChange={(e, nv, reason) =>
                        changeForm("especialidade", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("especialidade", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("especialidade", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "especialidade"),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="titulo"
                    key={formKey.tituloKey}
                    options={itemsMetadataAgua.titulo}
                    label="Descrição"
                    onChange={(e, nv, reason) =>
                        changeForm("titulo", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("titulo", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("titulo", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "titulo"),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="codSabesp"
                    key={formKey.codSabespKey}
                    options={itemsMetadataAgua.codSabesp}
                    label="Código Sabesp"
                    onChange={(e, nv, reason) =>
                        changeForm("codSabesp", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("codSabesp", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("codSabesp", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "codSabesp"),
                        role: "list-box",
                    }}
                />
            </div>

            <div className="buttons-container">
                <Link
                    href="https://youtu.be/4SFwLygA3MU?si=Bx8yJs4ZHLr8UYUF"
                    target="_blank"
                    underline="none"
                >
                    <FormButton
                        id="Ajuda"
                        text="Ajuda"
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<InfoOutlinedIcon />}
                    />
                </Link>

                <FormButton
                    id="Limpar"
                    text="Limpar"
                    onClick={() => {
                        handleClear();
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<RefreshSharpIcon />}
                />
                <FormButton
                    id="Pesquisar"
                    text="Pesquisar"
                    onClick={() => {
                        filterFolderContentsAgua(form);
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SearchSharpIcon />}
                />
            </div>

            <Snackbar
                open={showSuccessAlert}
                sx={{ height: "100%" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="success">{successMessage}</Alert>
            </Snackbar>
            <Snackbar
                open={showErrorAlert}
                sx={{ height: "100%" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
        </>
    );
};

export default FormAgua;
