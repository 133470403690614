import React from 'react';
import styles from './index.module.css';

import Title from '../../atoms/Title';
import Paragraph from '../../atoms/Paragraph';

/**
 * Card component displaying a title and a paragraph.
 * Tells the user the purpose of the system and briefly describe how to use it.
 */
const Card = () => {
  return (
    <div className={styles.card}>
      <Title content="Migração de Metadados - SAT" id="formTitle"/>
      <Paragraph content="Para migrar metadados para o ambiente Autodesk, clique no botão abaixo e escolha o arquivo que deseja migrar. O sistema suporta apenas arquivos Excel (.xlsx). No arquivo Excel, a aba (ou planilha) deve ter o mesmo nome da pasta na Autodesk Construction Cloud. Além disso, certifique-se de que todas as células que contêm nomes de arquivos incluam a extensão do arquivo (por exemplo, 'documento.pdf')." id="formDescription"/>
    </div>
  )
}

export default Card;