import { getUserData } from "../../../service/AecFolderService";
import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

/**
 * API functions for autodesk-project-related operations.
 * @namespace projectsApi
 */
export const projectsApi = {
  /**
   * Fetches the especified user's roles from the Autodesk Api.
   * @async
   * @param {string} [projectId] - The ID of the project for which to fetch user roles.
   * @param {boolean} [cancel=false] - Flag indicating whether to enable request cancellation.
   */
  getUserRolesByProject: async function (projectId, cancel = false) {
    const token = localStorage.getItem("access_token");
    const userData = await getUserData();

    const response = await api.request({
      url: `${process.env.REACT_APP_API_BASE_URL}construction/admin/v1/projects/${projectId}/users`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    const result = response.data.results
      ?.find((user) => user.email === userData.data.email)
      ?.roles?.filter((role) => !!role);

    return result;
  },
};

const cancelApiObject = defineCancelApiObject(projectsApi); // Object for managing request cancellation for each API endpoint in projectsApi
