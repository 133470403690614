import React from 'react';
import styles from './index.module.css';

import FormBox from '../../organisms/FormBox';
import Footer from '../../../../../containers/footer';
import Header from '../../../../../components/Header';

/**
 * Form template component containing a FormBox organism and the page footer.
 */
const Form = () => {
  return (
    <div className={styles.form}>
      <Header/>
      <FormBox/>
      <Footer/>
    </div>
  )
}

export default Form;