import { useTheme } from "@mui/material";
import visualBimLogoDark from "../../assets/visual_bim_logo-dark.png";
import visualBimLogoLight from "../../assets/visual_bim_logo-light.png";
import { useNavigate } from "react-router-dom";

const visualBimLogoStyle = {
    width: "280px",
    height: "75px",
    cursor: "pointer", 
};

export const VisualBimLogo = () => {
    const theme = useTheme();

    let navigate = useNavigate();    

    const handleHome = () => {
        let path = `/`;
        navigate(path);
    };


    return (
        <>
             <div 
             onClick={handleHome}
             style={visualBimLogoStyle}
            >
                <img
                    style={visualBimLogoStyle}
                    key={theme.palette.mode}
                    src={
                        theme.palette.mode === "dark"
                            ? visualBimLogoLight
                            : visualBimLogoDark
                    }
                    alt="Visual BIM Logo"
                />
            </div>
        </>
    );
};
