import { useContext, useReducer, useState } from "react";
import { Alert, Snackbar, Link } from "@mui/material";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AecFolderContext } from "../../../Provider/AecFolderProvider";
import FormAutocomplete from "../Autocomplete";
import FormButton from "../Button";
import { AlertContext } from "../../../Provider/AlertProvider";
import { DateRangePicker } from "rsuite";
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import { useNavigate } from "react-router-dom";
import "./ArquivoTecnico.css";

const FormOSE = () => {
    const {
        form,
        changeFormOse,
        itemsMetadataOse,
        handleClearOse,
        handleHelp,
        loadFieldOse,
        filterFolderContentsOse,
    } = useContext(AecFolderContext);

    const { errorMessage, successMessage, showSuccessAlert, showErrorAlert } =
        useContext(AlertContext);

    const [formKey, setFormKey] = useState({
        itemNameKey: Math.random(),
        imobilizadoKey: Math.random(),
        classeImobilizadoKey: Math.random(),
        descricaoClasseKey: Math.random(),
        uarKey: Math.random(),
        centroCustoKey: Math.random(),
        plantaGlobalKey: Math.random(),
        imobOrigemKey: Math.random(),
        pepKey: Math.random(),
        exercicioAquisicaoKey: Math.random(),
        materialTubulacaoKey: Math.random(),
        diametroTubulacaoKey: Math.random(),
        metodoConstrutivoTubulacaoKey: Math.random(),
        localizacaoKey: Math.random(),
        desativadoEmKey: Math.random(),
        unKey: Math.random(),
        municipioKey: Math.random(),
        contratoSapKey: Math.random(),
        contratoSglKey: Math.random(),
        numeroProtocoloSatKey: Math.random(),
        informacoesRecebimentoSatKey: Math.random(),
    });

    const [defaultDataDepreciacao, setDefaultDataDepreciacao] = useState();
    const [defaultDataIncorporacao, setDefaultDataIncorporacao] = useState();
    const [defaultDataProtocoloSat, setDefaultDataProtocoloSat] = useState();

    const [dataDepreciacao, setDataDepreciacao] = useState();
    const [dataIncorporacao, setDataIncorporacao] = useState();
    const [dataProtocoloSat, setDataProtocoloSat] = useState();

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    let navigate = useNavigate();

    function enviarData(data, field) {
        if (field === "dataProtocoloSat") {
            setDataProtocoloSat(data);
            const startDate = data[0].toLocaleDateString();
            const endDate = data[1].toLocaleDateString();

            const dateRangeString = `${startDate} - ${endDate}`;
            changeForm("dataProtocoloSat", "onChange", data, dateRangeString);
        } else if (field === "dataDepreciacao") {
            setDataDepreciacao(data);
            const startDate = data[0].toLocaleDateString();
            const endDate = data[1].toLocaleDateString();

            const dateRangeString = `${startDate} - ${endDate}`;
            changeForm("dataDepreciacao", "onChange", data, dateRangeString);
        } else if (field === "dataIncorporacao") {
            setDataIncorporacao(data);
            const startDate = data[0].toLocaleDateString();
            const endDate = data[1].toLocaleDateString();

            const dateRangeString = `${startDate} - ${endDate}`;
            changeForm("dataIncorporacao", "onChange", data, dateRangeString);
        }
    }
    const handleClear = async () => {
        setFormKey({
            itemNameKey: Math.random(),
            imobilizadoKey: Math.random(),
            classeImobilizadoKey: Math.random(),
            descricaoClasseKey: Math.random(),
            uarKey: Math.random(),
            centroCustoKey: Math.random(),
            plantaGlobalKey: Math.random(),
            imobOrigemKey: Math.random(),
            pepKey: Math.random(),
            exercicioAquisicaoKey: Math.random(),
            materialTubulacaoKey: Math.random(),
            diametroTubulacaoKey: Math.random(),
            metodoConstrutivoTubulacaoKey: Math.random(),
            localizacaoKey: Math.random(),
            desativadoEmKey: Math.random(),
            unKey: Math.random(),
            municipioKey: Math.random(),
            contratoSapKey: Math.random(),
            contratoSglKey: Math.random(),
            numeroProtocoloSatKey: Math.random(),
            informacoesRecebimentoSatKey: Math.random(),
        });
        await handleClearOse();
        setDataProtocoloSat(null);
        setDataDepreciacao(null);
        setDataIncorporacao(null);
        setDefaultDataProtocoloSat([
            new Date(itemsMetadataOse.dataProtocoloSatde),
            new Date(itemsMetadataOse.dataProtocoloSatate),
        ]);
        setDefaultDataDepreciacao([
            new Date(itemsMetadataOse.dataDepreciacaode),
            new Date(itemsMetadataOse.dataDepreciacaoate),
        ]);
        setDefaultDataIncorporacao([
            new Date(itemsMetadataOse.dataIncorporacaode),
            new Date(itemsMetadataOse.dataIncorporacaoate),
        ]);
        forceUpdate();
    };

    const changeForm = (field, method, e = {}, newValue = "", reason = "") => {
        changeFormOse(field, method, e, newValue, reason);
        forceUpdate();
    };

    const onScrollField = async (currentTarget, field) => {
        if (
            Math.round(currentTarget.scrollTop) +
            Math.round(currentTarget.clientHeight) ===
            Math.round(currentTarget.scrollHeight)
        ) {
            await loadFieldOse(
                "ose",
                "onScroll",
                field,
                Math.round(itemsMetadataOse[field].length / 200)
            );
            forceUpdate();
        }
    };

    const handleMigration = () => {
        let path = `/migracao`;
        navigate(path);
    };

    return (
        <>
            <div className="fields-container-geral">
                <FormAutocomplete
                    id="itemName"
                    key={formKey.itemNameKey}
                    options={itemsMetadataOse.itemName}
                    label="Nome do Arquivo"
                    onChange={(e, nv, reason) =>
                        changeForm("itemName", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("itemName", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("itemName", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "itemName"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="imobilizado"
                    key={formKey.imobilizadoKey}
                    options={itemsMetadataOse.imobilizado}
                    label="Imobilizado"
                    onChange={(e, nv, reason) =>
                        changeForm("imobilizado", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("imobilizado", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("imobilizado", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "imobilizado"),
                        role: "list-box",
                    }}
                />
                <DateRangePicker
                    format="dd/MM/yyyy"
                    placeholder="Data Incorporação"
                    showOneCalendar={true}
                    style={{ width: 300 }}
                    cleanable={false}
                    defaultCalendarValue={
                        defaultDataIncorporacao || [
                            new Date(itemsMetadataOse.dataIncorporacaode),
                            new Date(itemsMetadataOse.dataIncorporacaoate),
                        ]
                    }
                    value={dataIncorporacao}
                    onOk={(e) => enviarData(e, "dataIncorporacao")}
                />

                <DateRangePicker
                    format="dd/MM/yyyy"
                    placeholder="Data Depreciação"
                    showOneCalendar={true}
                    style={{ width: 300 }}
                    cleanable={false}
                    defaultCalendarValue={
                        defaultDataDepreciacao || [
                            new Date(itemsMetadataOse.dataDepreciacaode),
                            new Date(itemsMetadataOse.dataDepreciacaoate),
                        ]
                    }
                    value={dataDepreciacao}
                    onOk={(e) => enviarData(e, "dataDepreciacao")}
                />

                <FormAutocomplete
                    id="classeImobilizado"
                    key={formKey.classeImobilizadoKey}
                    options={itemsMetadataOse.classeImobilizado}
                    label="Classe do Imobilizado"
                    onChange={(e, nv, reason) =>
                        changeForm("classeImobilizado", "onChange", e, nv.toUpperCase(), reason
                        )
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("classeImobilizado", "onKeyUp", e.target.value.toUpperCase())
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("classeImobilizado", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(
                                currentTarget,
                                "classeImobilizado"
                            ),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="descricaoClasse"
                    key={formKey.descricaoClasseKey}
                    options={itemsMetadataOse.descricaoClasse}
                    label="Descrição da Classe"
                    onChange={(e, nv, reason) =>
                        changeForm("descricaoClasse", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("descricaoClasse", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("descricaoClasse", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(
                                currentTarget,
                                "descricaoClasse"
                            ),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="uar"
                    key={formKey.uarKey}
                    options={itemsMetadataOse.uar}
                    label="UAR"
                    onChange={(e, nv, reason) =>
                        changeForm("uar", e.target.innerText, "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("uar", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("uar", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "uar"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="centroCusto"
                    key={formKey.centroCustoKey}
                    options={itemsMetadataOse.centroCusto}
                    label="Centro de Custo"
                    onChange={(e, nv, reason) =>
                        changeForm("centroCusto", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("centroCusto", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("centroCusto", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "centroCusto"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="plantaGlobal"
                    key={formKey.plantaGlobalKey}
                    options={itemsMetadataOse.plantaGlobal}
                    label="Planta Global"
                    onChange={(e, nv, reason) =>
                        changeForm("plantaGlobal", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("plantaGlobal", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("plantaGlobal", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "plantaGlobal"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="imobOrigem"
                    key={formKey.imobOrigemKey}
                    options={itemsMetadataOse.imobOrigem}
                    label="Imob. De Origem"
                    onChange={(e, nv, reason) =>
                        changeForm("imobOrigem", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("imobOrigem", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("imobOrigem", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "imobOrigem"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="pep"
                    key={formKey.pepKey}
                    options={itemsMetadataOse.pep}
                    label="PEP"
                    onChange={(e, nv, reason) =>
                        changeForm("pep", e.target.innerText, "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("pep", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("pep", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "pep"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="exercicioAquisicao"
                    key={formKey.exercicioAquisicaoKey}
                    options={itemsMetadataOse.exercicioAquisicao}
                    label="Exercício da Aquisição"
                    onChange={(e, nv, reason) =>
                        changeForm("exercicioAquisicao", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("exercicioAquisicao", "onKeyUp", e.target.value.toUpperCase())
                    }
                    }
                    onChangeTextfield={(e) =>
                        changeForm("exercicioAquisicao", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(
                                currentTarget,
                                "exercicioAquisicao"
                            ),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="materialTubulacao"
                    key={formKey.materialTubulacaoKey}
                    options={itemsMetadataOse.materialTubulacao}
                    label="Material (Tubulação)"
                    onChange={(e, nv, reason) =>
                        changeForm("materialTubulacao", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("materialTubulacao", "onKeyUp", e.target.value.toUpperCase())
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("materialTubulacao", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }

                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(
                                currentTarget,
                                "materialTubulacao"
                            ),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="diametroTubulacao"
                    key={formKey.diametroTubulacaoKey}
                    options={itemsMetadataOse.diametroTubulacao}
                    label="Diâmetro (Tubulação)"
                    onChange={(e, nv, reason) =>
                        changeForm(
                            "diametroTubulacao", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("diametroTubulacao", "onKeyUp", e.target.value.toUpperCase())
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("diametroTubulacao", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(
                                currentTarget,
                                "diametroTubulacao"
                            ),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="metodoConstrutivoTubulacao"
                    key={formKey.metodoConstrutivoTubulacaoKey}
                    options={itemsMetadataOse.metodoConstrutivoTubulacao}
                    label="Método Construtivo (Tubulação)"
                    onChange={(e, nv, reason) =>
                        changeForm(
                            "metodoConstrutivoTubulacao", "onChange", e, nv.toUpperCase(), reason)

                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("metodoConstrutivoTubulacao", "onKeyUp", e.target.value.toUpperCase())
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("metodoConstrutivoTubulacao", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(
                                currentTarget,
                                "metodoConstrutivoTubulacao"
                            ),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="localizacao"
                    key={formKey.localizacaoKey}
                    options={itemsMetadataOse.localizacao}
                    label="Localização"
                    onChange={(e, nv, reason) =>
                        changeForm("localizacao", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("localizacao", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("localizacao", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "localizacao"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="desativadoEm"
                    key={formKey.desativadoEmKey}
                    options={itemsMetadataOse.desativadoEm}
                    label="Desativado em"
                    onChange={(e, nv, reason) =>
                        changeForm("desativadoEm", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("desativadoEm", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("desativadoEm", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "desativadoEm"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="un"
                    key={formKey.unKey}
                    options={itemsMetadataOse.un}
                    label="UN"
                    onChange={(e, nv, reason) =>
                        changeForm("un", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("un", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("un", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "un"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="municipio"
                    key={formKey.municipioKey}
                    options={itemsMetadataOse.municipio}
                    label="Município"
                    onChange={(e, nv, reason) =>
                        changeForm("municipio", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("municipio", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("municipio", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "municipio"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="contratoSap"
                    key={formKey.contratoSapKey}
                    options={itemsMetadataOse.contratoSap}
                    label="Contrato SAP"
                    onChange={(e, nv, reason) =>
                        changeForm("contratoSap", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("contratoSap", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("contratoSap", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "contratoSap"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="contratoSgl"
                    key={formKey.contratoSglKey}
                    options={itemsMetadataOse.contratoSgl}
                    label="Contrato SGL"
                    onChange={(e, nv, reason) =>
                        changeForm("contratoSgl", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("contratoSgl", "onKeyUp", e, e.target.value.toUpperCase(), reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("contratoSgl", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "contratoSgl"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="numeroProtocoloSat"
                    key={formKey.numeroProtocoloSatKey}
                    options={itemsMetadataOse.numeroProtocoloSat}
                    label="Número Protocolo SAT"
                    onChange={(e, nv, reason) =>
                        changeForm("numeroProtocoloSat", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("numeroProtocoloSat", "onKeyUp", e.target.value.toUpperCase())
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("numeroProtocoloSat", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(
                                currentTarget,
                                "numeroProtocoloSat"
                            ),
                        role: "list-box",
                    }}
                />
                <DateRangePicker
                    format="dd/MM/yyyy"
                    placeholder="Data Protocolo Sat"
                    showOneCalendar={true}
                    style={{ width: 300 }}
                    defaultCalendarValue={
                        defaultDataProtocoloSat || [
                            new Date(itemsMetadataOse.dataProtocoloSatde),
                            new Date(itemsMetadataOse.dataProtocoloSatate),
                        ]
                    }
                    value={dataProtocoloSat}
                    onOk={(e) => enviarData(e, "dataProtocoloSat")}
                />
                <FormAutocomplete
                    id="informacoesRecebimentoSat"
                    key={formKey.informacoesRecebimentoSatKey}
                    options={itemsMetadataOse.informacoesRecebimentoSat}
                    label="Informações dos Recebimentos Sat"
                    onChange={(e, nv, reason) =>
                        changeForm("informacoesRecebimentoSat", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter")
                            changeForm("informacoesRecebimentoSat", "onKeyUp", e.target.value.toUpperCase())
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("informacoesRecebimentoSat", "onChangeTextfield", e, e.target.value.toUpperCase())
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(
                                currentTarget,
                                "informacoesRecebimentoSat"
                            ),
                        role: "list-box",
                    }}
                />
            </div>

            <div className="buttons-container">
                <FormButton
                    id="Migracao"
                    text="Migração"
                    onClick={() => {
                        handleMigration();
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<BackupOutlinedIcon />}
                />
                <Link
                    href="https://www.youtube.com/watch?v=7FQpNy9_3q4"
                    target="_blank"
                    underline="none"
                >
                    <FormButton
                        id="Ajuda"
                        text="Ajuda"
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<InfoOutlinedIcon />}
                    />
                </Link>
                <FormButton
                    id="Limpar"
                    text="Limpar"
                    onClick={() => {
                        handleClear();
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<RefreshSharpIcon />}
                />
                <FormButton
                    id="Pesquisar"
                    text="Pesquisar"
                    onClick={() => {
                        filterFolderContentsOse(form);
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SearchSharpIcon />}
                />
            </div>

            <Snackbar
                open={showSuccessAlert}
                sx={{ height: "100%" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="success">{successMessage}</Alert>
            </Snackbar>
            <Snackbar
                open={showErrorAlert}
                sx={{ height: "100%" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
        </>
    );
};

export default FormOSE;
