import { useState } from 'react';
import { filesApi } from '../apis/filesApi';

/**
 * Custom hook for file migration functionality.
 * Handles file selection, migration process, and response management.
 * @returns {object} An object containing response state, selected file, button disabled state,
 * selectFile function for handling file selection, and migrateFile function for initiating file migration.
 */
const useFileMigration = () => {
  // State variables
  const [response, setResponse] = useState(null);
  const [file, setFile] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);

  /**
   * Function to handle file selection.
   * @param {File} inputFile - The selected file object.
   */
  const selectFile = (inputFile) => setFile(inputFile);

  /**
   * Async function to initiate file migration process.
   * Handles file format validation, API call, response handling, and state updates.
   * @param {React.MutableRefObject<null>} fileInputRef - Reference to the file input element used to clear its value after migration.
   */
  const migrateFile = async (fileInputRef, selectedProject) => {
    if (file) { // Validate if file is selected
      if (!file.name.endsWith(".xlsx")) { // Validate file format
        setResponse({ status: 500, message: "Formato de arquivo inválido" });
        setFile(null);
        return;
      }
      
      setBtnDisabled(true); // Disable migration button during process
      try {
        const response = await filesApi.sendMessage(file, selectedProject); // Call API to migrate file
        response.message = `(${file.name}) ${response.message}`; // Append file name to response message for clarity
        setResponse(response); // Update response state with API response
        
        // Clear selected file and enable button
        setFile(null);
        if(fileInputRef.current) fileInputRef.current.value = null;
        setBtnDisabled(false);
      }
      catch(error) { // Handle API call error
        setResponse({ status: error.response.status, message: error.response.data.error || error.message });
        setFile(null);
        if(fileInputRef.current) fileInputRef.current.value = null;
        setBtnDisabled(false);
      }
    }

    else setResponse({ status: 500, message: "Selecione um arquivo" }); // No file selected error response
  };


  return { response, file, btnDisabled, selectFile, migrateFile }; // Return state variables and functions as an object
};

export default useFileMigration;
