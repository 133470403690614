import { Card } from "@mui/material";
import { useContext, useEffect } from "react";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import Header from "../../components/Header";
import Footer from "../../containers/footer";
import FormAdministracao from "../../components/Form/Administracao";
import "./Administracao.css";


const Administracao = () => {
    const cardContainer = {
        borderRadius: "8px",
        padding: "24px",
        margin: "24px 48px",
        display: "flex",
        flexDirection: 'column'

    };
    // Destructuring userData and getUser from the AecFolderContext
    const { userData, getUser } = useContext(AecFolderContext);


    useEffect(() => {
        const fetchData = async () => {// Call getUser function to fetch user data
            await getUser();
        };

        fetchData();
    }, []);

    return (
        <>
            <Header />
            <main className="main-adm">
                <Card style={cardContainer}>
                    {/*Conditional rendering based on user company and access level */}
                    {/*The administration screen is only accessed if the user belongs to the company FF solutions and has the access level as administrator*/}
                    {userData.company === "FF Solutions" && userData.access === 'account_admin' ? <FormAdministracao /> : <div className="loader-signin-text"><p>ACESSO NEGADO</p></div>}
                </Card>
            </main>
            <Footer />
        </>
    );
};

export default Administracao;
