import { useContext, useReducer, useState } from "react";
import { Alert, Snackbar, Link } from "@mui/material";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AecFolderContext } from "../../../Provider/AecFolderProvider";
import FormAutocomplete from "../Autocomplete";
import FormButton from "../Button";
import { AlertContext } from "../../../Provider/AlertProvider";
import "./Esgoto.css";

const FormEsgoto = () => {
    const {
        form,
        changeFormEsgoto,
        itemsMetadataEsgoto,
        handleClearEsgoto,
        loadFieldEsgoto,
        filterFolderContentsEsgoto,
        handleHelp,
    } = useContext(AecFolderContext);

    const { errorMessage, successMessage, showSuccessAlert, showErrorAlert } =
        useContext(AlertContext);

    const [formKey, setFormKey] = useState({
        loteKey: Math.random(),
        nContratoKey: Math.random(),
        nCadastroKey: Math.random(),
        nContratadaKey: Math.random(),
        numeroKey: Math.random(),
        eeeKey: Math.random(),
        eteKey: Math.random(),
        emissarioKey: Math.random(),
        coletorTroncoKey: Math.random(),
        interceptorKey: Math.random(),
        especialidadeKey: Math.random(),
        tituloKey: Math.random(),
        baciaEsgotamentoKey: Math.random(),
        municipioKey: Math.random(),
    });

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const handleClear = async () => {
        setFormKey({
            loteKey: Math.random(),
            nContratoKey: Math.random(),
            nCadastroKey: Math.random(),
            nContratadaKey: Math.random(),
            numeroKey: Math.random(),
            eeeKey: Math.random(),
            eteKey: Math.random(),
            emissarioKey: Math.random(),
            coletorTroncoKey: Math.random(),
            interceptorKey: Math.random(),
            especialidadeKey: Math.random(),
            tituloKey: Math.random(),
            baciaEsgotamentoKey: Math.random(),
            municipioKey: Math.random(),
        });
        await handleClearEsgoto();
        forceUpdate();
    };

    const changeForm = (field, method, e = {}, newValue = "", reason = "") => {
        changeFormEsgoto(field, method, e, newValue, reason);
        forceUpdate();
    };

    const onScrollField = async (currentTarget, field) => {
        if (
            Math.round(currentTarget.scrollTop) +
            Math.round(currentTarget.clientHeight) ===
            Math.round(currentTarget.scrollHeight)
        ) {
            await loadFieldEsgoto(
                "esgoto",
                "onScroll",
                field,
                Math.round(itemsMetadataEsgoto[field].length / 200)
            );
            forceUpdate();
        }
    };

    return (
        <>
            <div className="fields-container">
                <div className="subgrid">
                    <FormAutocomplete
                        id="nContrato"
                        key={formKey.nContratoKey}
                        options={itemsMetadataEsgoto.nContrato}
                        label="Nº do Contrato"
                        onChange={(e, nv, reason) =>
                            changeForm("nContrato", "onChange", e, nv.toUpperCase(), reason)
                        }
                        onKeyUp={(e, nv, reason) => {
                            if (e.key === "Enter") changeForm("nContrato", "onKeyUp", e, e.target.value, reason)
                        }}
                        onChangeTextfield={(e) =>
                            changeForm("nContrato", "onChangeTextfield", e, e.target.value)
                        }
                        filterOptions={(options) => options}
                        listboxProps={{
                            onScroll: async ({ e, currentTarget }) =>
                                await onScrollField(currentTarget, "nContrato"),
                            role: "list-box",
                        }}
                    />
                    <FormAutocomplete
                        id="nCadastro"
                        key={formKey.nCadastroKey}
                        options={itemsMetadataEsgoto.nCadastro}
                        label="Nº Cadastro"
                        onChange={(e, nv, reason) =>
                            changeForm("nCadastro", "onChange", e, nv.toUpperCase(), reason)
                        }
                        onKeyUp={(e, nv, reason) => {
                            if (e.key === "Enter") changeForm("nCadastro", "onKeyUp", e, e.target.value, reason)
                        }}
                        onChangeTextfield={(e) =>
                            changeForm("nCadastro", "onChangeTextfield", e, e.target.value)
                        }
                        filterOptions={(options) => options}
                        listboxProps={{
                            onScroll: async ({ e, currentTarget }) =>
                                await onScrollField(currentTarget, "nCadastro"),
                            role: "list-box",
                        }}
                    />
                    <FormAutocomplete
                        id="nContratada"
                        key={formKey.nContratadaKey}
                        options={itemsMetadataEsgoto.nContratada}
                        label="Nº da Contratada"
                        onChange={(e, nv, reason) =>
                            changeForm("nContratada", "onChange", e, nv.toUpperCase(), reason)
                        }
                        onKeyUp={(e, nv, reason) => {
                            if (e.key === "Enter") changeForm("nContratada", "onKeyUp", e, e.target.value, reason)
                        }}
                        onChangeTextfield={(e) =>
                            changeForm("nContratada", "onChangeTextfield", e, e.target.value)
                        }
                        filterOptions={(options) => options}
                        listboxProps={{
                            onScroll: async ({ e, currentTarget }) =>
                                await onScrollField(
                                    currentTarget,
                                    "nContratada"
                                ),
                            role: "list-box",
                        }}
                    />
                    <FormAutocomplete
                        id="numero"
                        key={formKey.numeroKey}
                        options={itemsMetadataEsgoto.numero}
                        label="Nº"
                        onChange={(e, nv, reason) =>
                            changeForm("numero", "onChange", e, nv.toUpperCase(), reason)
                        }
                        onKeyUp={(e, nv, reason) => {
                            if (e.key === "Enter") changeForm("numero", "onKeyUp", e, e.target.value, reason)
                        }}
                        onChangeTextfield={(e) =>
                            changeForm("numero", "onChangeTextfield", e, e.target.value)
                        }
                        filterOptions={(options) => options}
                        listboxProps={{
                            onScroll: async ({ e, currentTarget }) =>
                                await onScrollField(currentTarget, "numero"),
                            role: "list-box",
                        }}
                    />
                </div>

                <FormAutocomplete
                    id="lote"
                    key={formKey.loteKey}
                    options={itemsMetadataEsgoto.lote}
                    label="Lote"
                    onChange={(e, nv, reason) =>
                        changeForm("lote", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("lote", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("lote", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "lote"),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="baciaEsgotamento"
                    key={formKey.baciaEsgotamentoKey}
                    options={itemsMetadataEsgoto.baciaEsgotamento}
                    label="Bacia de Esgotamento"
                    onChange={(e, nv, reason) =>
                        changeForm(
                            "baciaEsgotamento", "onChange", e, nv, reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("baciaEsgotamento", "onKeyUp", e, e.target.value, reason)
                    }
                    }
                    onChangeTextfield={(e) =>
                        changeForm("baciaEsgotamento", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(
                                currentTarget,
                                "baciaEsgotamento"
                            ),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="eee"
                    key={formKey.eeeKey}
                    options={itemsMetadataEsgoto.eee}
                    label="Estação Elevatória de Esgotos (EEE)"
                    onChange={(e, nv, reason) =>
                        changeForm("eee", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("eee", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("eee", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "eee"),
                        role: "list-box",
                    }}
                />

                <FormAutocomplete
                    id="ete"
                    key={formKey.eteKey}
                    options={itemsMetadataEsgoto.ete}
                    label="Estação de Tratamento de Esgotos (ETE)"
                    onChange={(e, nv, reason) =>
                        changeForm("ete", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("ete", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("ete", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "ete"),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="emissario"
                    key={formKey.emissarioKey}
                    options={itemsMetadataEsgoto.emissario}
                    label="Emissário"
                    onChange={(e, nv, reason) =>
                        changeForm("emissario", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("emissario", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("emissario", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "emissario"),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="coletorTronco"
                    key={formKey.coletorTroncoKey}
                    options={itemsMetadataEsgoto.coletorTronco}
                    label="Coletor Tronco"
                    onChange={(e, nv, reason) =>
                        changeForm("coletorTronco", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("coletorTronco", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("coletorTronco", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ event, currentTarget }) =>
                            await onScrollField(currentTarget, "municipio"),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="interceptor"
                    key={formKey.interceptorKey}
                    options={itemsMetadataEsgoto.interceptor}
                    label="Interceptor"
                    onChange={(e, nv, reason) =>
                        changeForm("interceptor", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("interceptor", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("interceptor", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ event, currentTarget }) =>
                            await onScrollField(currentTarget, "interceptor"),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="especialidade"
                    key={formKey.especialidadeKey}
                    options={itemsMetadataEsgoto.especialidade}
                    label="Especialidade"
                    onChange={(e, nv, reason) =>
                        changeForm("especialidade", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("especialidade", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("especialidade", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "especialidade"),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="municipio"
                    key={formKey.municipioKey}
                    options={itemsMetadataEsgoto.municipio}
                    label="Município"
                    onChange={(e, nv, reason) =>
                        changeForm("municipio", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("municipio", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("municipio", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "municipio"),
                        role: "list-box",
                    }}
                />
                <FormAutocomplete
                    id="titulo"
                    key={formKey.tituloKey}
                    options={itemsMetadataEsgoto.titulo}
                    label="Título"
                    onChange={(e, nv, reason) =>
                        changeForm("titulo", "onChange", e, nv.toUpperCase(), reason)
                    }
                    onKeyUp={(e, nv, reason) => {
                        if (e.key === "Enter") changeForm("titulo", "onKeyUp", e, e.target.value, reason)
                    }}
                    onChangeTextfield={(e) =>
                        changeForm("titulo", "onChangeTextfield", e, e.target.value)
                    }
                    filterOptions={(options) => options}
                    listboxProps={{
                        onScroll: async ({ e, currentTarget }) =>
                            await onScrollField(currentTarget, "titulo"),
                        role: "list-box",
                    }}
                />
            </div>

            <div className="buttons-container">
                <Link
                    href="https://www.youtube.com/watch?v=19202F0dVTI"
                    target="_blank"
                    underline="none"
                >
                    <FormButton
                        id="Ajuda"
                        text="Ajuda"
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<InfoOutlinedIcon />}
                    />
                </Link>
                <FormButton
                    id="Limpar"
                    text="Limpar"
                    onClick={() => {
                        handleClear();
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<RefreshSharpIcon />}
                />
                <FormButton
                    id="Pesquisar"
                    text="Pesquisar"
                    onClick={() => {
                        filterFolderContentsEsgoto(form);
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SearchSharpIcon />}
                />
            </div>

            <Snackbar
                open={showSuccessAlert}
                sx={{ height: "100%" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="success">{successMessage}</Alert>
            </Snackbar>
            <Snackbar
                open={showErrorAlert}
                sx={{ height: "100%" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
        </>
    );
};

export default FormEsgoto;
