import ReactDOM from "react-dom/client";
import React from "react";

import App from "./App";
import "./App.css";
import { AuthProvider } from "./Provider/AuthProvider";
import { AecFolderProvider } from "./Provider/AecFolderProvider";
import { LoadingProvider } from "./Provider/LoadingProvider";
import { AlertProvider } from "./Provider/AlertProvider";
import { BrowserRouter } from "react-router-dom";
import { ProjectProvider } from "./Provider/ProjectProvider";
import { ColorModeProvider } from "./Provider/ColorModeProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ColorModeProvider>
        <LoadingProvider>
            <AlertProvider>
                <ProjectProvider>
                    <AecFolderProvider>
                        <AuthProvider>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </AuthProvider>
                    </AecFolderProvider>
                </ProjectProvider>
            </AlertProvider>
        </LoadingProvider>
    </ColorModeProvider>
);
