import apiRequest from "../apiRequest";

var currentHost = window.location.protocol + "//" + window.location.host;

export function getTwoLeggedToken() {
    var url = process.env.REACT_APP_API_BASE_URL + 'authentication/v2/token';
    var data = {
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        client_id: process.env.REACT_APP_CLIENT_ID,
        grant_type: 'client_credentials',
        scope: 'data:write data:create data:read bucket:create bucket:delete'
    };

    var response = apiRequest.post(url, new URLSearchParams(data), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });

    return response;
}

export function getTokenAuthenticate() {
    var url = process.env.REACT_APP_API_BASE_URL + 'authentication/v2/token';
    var data = {
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        client_id: process.env.REACT_APP_CLIENT_ID,
        grant_type: 'client_credentials',
        scope: 'account:read data:read'
    };

    var response = apiRequest.post(url, new URLSearchParams(data), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });

    return response;
}


export async function redirectToAuth() {
    var url = encodeURI(process.env.REACT_APP_API_BASE_URL +
        'authentication/v2/authorize?response_type=code&client_id=' +
        process.env.REACT_APP_CLIENT_ID +
        '&redirect_uri=' +
        currentHost +
        '&scope=data:read viewables:read bucket:create data:create account:read');
    window.location.href = url;
}

export async function fetchToken(code) {
    var data = {
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        client_id: process.env.REACT_APP_CLIENT_ID,
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: currentHost
    };
    var url = process.env.REACT_APP_API_BASE_URL + 'authentication/v2/token';

    var response = apiRequest.post(url, new URLSearchParams(data), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })

    return response;
}


export async function RefreshToken(refresh_token) {
    var data = {
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        client_id: process.env.REACT_APP_CLIENT_ID,
        grant_type: 'refresh_token',
        refresh_token: refresh_token,
        redirect_uri: currentHost,
        scope: 'data:read account:read'
    };

    var url = process.env.REACT_APP_API_BASE_URL + 'authentication/v2/token';

    var response = await apiRequest.post(url, new URLSearchParams(data), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })

    return response;
}